import { Grid, Typography } from "@mui/material"

interface IOrgInfoTrackerListingHeaderItem {
    children: React.ReactNode;
    conditionalToHaveEndAlignment: boolean;
}

export const OrgInfoTrackerListingHeaderItem: React.FunctionComponent<IOrgInfoTrackerListingHeaderItem> = ({
    children,
    conditionalToHaveEndAlignment
}) => {
    return (
        <Grid item xs={3}>
            <Typography sx={{
                textAlign: `${conditionalToHaveEndAlignment ? "end" : "left"}`,
                font: "normal normal 600 12px/18px Segoe UI",
                letterSpacing: "0px",
                color: "#2D2D2D",
                opacity: 0.5,
            }}>
                { children }
            </Typography>
        </Grid>
    )
}