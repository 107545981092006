import { useEffect, useState } from 'react';
import DocumentDetailTableHeader from '../DocumentDetailTable/DocumentDetailTableHeader/DocumentDetailTableHeader';
import { Box, Table, TableBody, TableCell } from '@mui/material';
import DocumentDetailTabTitle from '../DocumentDetailTabTitle/DocumentDetailTabTitle';
import { WorkSpaceItemRevision } from '../../../../../../../../model/ItemModel';
import DocumentDetailTableBody from '../DocumentDetailTable/DocumentDetailTableBody/DocumentDetailTableBody';
import DocumentDetailTableWrapper from '../DocumentDetailTable/DocumentDetailTableWrapper/DocumentDetailTableWrapper';
import moment from 'moment';
import LoaderComp from '../../../../../../../../components/Loader/LoaderComp';
import { IShowInfo } from '../../../../../../DocumentDetail';
import { useTranslation } from 'react-i18next';
import descriptionIcon from '../../../../../../../../assets/icons/descrição.svg';
import Description from './components/Description/Description';
import DescriptionLayer from './components/DescriptionLayer/DescriptionLayer';

interface IDocumentDetailReviewTable {
    itemRevision: WorkSpaceItemRevision[];
    revisionLoading: boolean;
    setShowViwerInfo: (set: IShowInfo) => void;
    showViwerInfo: IShowInfo;
    isSidebarCollapsed: boolean;
    fullScreenSetted: boolean;
    isNotebookWidth: boolean
}

export default function DocumentDetailReviewTable({
    itemRevision,
    revisionLoading,
    setShowViwerInfo,
    showViwerInfo,
    fullScreenSetted,
    isSidebarCollapsed,
    isNotebookWidth
}: IDocumentDetailReviewTable)
{
    const { t } = useTranslation();
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [clickId, setClickId] = useState<number>(0);
    const [internalDescription, setInternalDescription] = useState<string | undefined>("");

    const dataTitles = [
        t("DataTableHeaders.Reviews"), 
        t("DataTableHeaders.Name"), 
        t("DataTableHeaders.Date"),
        t("DataTableHeaders.Description")]

    useEffect(() => {
        setShowViwerInfo({ ...showViwerInfo, value: '2' });
    }, [])

    function renderTitlesCells(item: string, index: number) 
    {
        return (
            <TableCell>
                <DocumentDetailTabTitle 
                    textToShowInButton={item}
                />
            </TableCell>
        )
    }

    function renderReviewsCells(item: WorkSpaceItemRevision, index: number)
    {
        return (
            <>
                <TableCell align="left" sx={{
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    fontWeight: 'bold'
                }}>
                    {item.enumerate}
                </TableCell>
                <TableCell align="left" sx={{
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    fontWeight: 'bold'
                }}>
                    {item.itemName}
                </TableCell>
                <TableCell align="left" sx={{
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    fontWeight: 'bold'
                }}>
                    {moment(item.draftDate).format("DD/MM/YYYY hh:mm")}
                </TableCell>
                <TableCell align="left" sx={{
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    fontWeight: 'bold'
                }} id={index.toString()}>
                    <Description
                        description={item?.description!}
                        setClickId={setClickId}
                        setShowDescription={setShowDescription}
                        index={index}
                        showDescription={showDescription}
                        setInternalDescription={setInternalDescription}
                    />
                </TableCell>
            </>
        )
    }

    if (revisionLoading) {
        return (
            <LoaderComp />
        )
    }

    return (
        <DocumentDetailTableWrapper
            fullScreenSetted={fullScreenSetted}
            isSidebarCollapsed={isSidebarCollapsed}
            isNotebookWidth={isNotebookWidth}
        >
            <DocumentDetailTableHeader<string>
                data={dataTitles}
                renderCells={renderTitlesCells}
            />
            <DocumentDetailTableBody<WorkSpaceItemRevision> 
                data={itemRevision}
                renderCells={renderReviewsCells}
                clickId={clickId}
                description={internalDescription}
                showDescription={showDescription}
            />
        </DocumentDetailTableWrapper>
    )
}