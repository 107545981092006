import React, { useEffect, useState, useRef, useCallback } from "react";
import { Organization } from "../../model/OrganizationModel";
import { GetOrganizationTrim,GetOrganizationTrimName } from "../../services/OrganizationServices";
import Message from "../../components/Message/Message";
import { Link, MessageBarType } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import styles from "../Style/List.module.css";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import { IconButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react';
import { fontWeight } from "@mui/system";
import { useTranslation } from "react-i18next";
import { AddOrganizationLocalStorage } from "../PublicLayout/RedirectManager";

export interface OrganizationListCompConfig {
  skip: number;
}

function useVisibility(
  cb: (isVisible: boolean) => void,
  deps: React.DependencyList
): (node: any) => void {
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  return useCallback((node) => {
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    intersectionObserver.current = new IntersectionObserver(([entry]) => {
      cb(entry.isIntersecting);
    });

    if (node) intersectionObserver.current.observe(node);
  }, deps);
}

interface OrgItemProps {
  nameOrg?: string | undefined;
  name?: string;
  ref?: React.Ref<HTMLLIElement>;
}

const OrgItem: React.ForwardRefExoticComponent<OrgItemProps> = React.forwardRef(
  ({ nameOrg, name }, ref: React.Ref<HTMLLIElement>) => {
    const navegate = useNavigate();
    const profile = useProfile();

    function redirect()
    {      
      if(nameOrg)
        AddOrganizationLocalStorage(nameOrg);
      if(profile.adminSystem)
      {
        navegate("/" + nameOrg);
      }
      else if(profile.organizations != undefined)
      {
        let admOrg:boolean = false;
        for(let organization of profile.organizations)
        {
          if(nameOrg == organization.internalName)
          {
            if(organization.isAdmin == true)
            {
              admOrg = true;
            }
            break;
          }
        }
        if(admOrg)
        {
          navegate("/" + nameOrg + "/users");
        }
        else
        {
          navegate("/" + nameOrg + "/wsallowed");
        }
      }
      else
      {
        navegate("/" + nameOrg);
      }
    }
    
    return (
      <li className={`${styles.itemContent} ${styles.rowOrg}`} key={nameOrg} ref={ref} onClick={redirect}>
          {name}{" "}
      </li>
      
    );
  }
);

interface ListOrgId {
  text:string;
}

const OrganizationListComp: React.FC<ListOrgId> = ({text}) => {
  const [org, setOrg] = useState<Organization[]>([]);
  const navegate = useNavigate();
  let [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(1000);
  let [orgMax, setOrgMax] = useState<boolean>(true);
  let [orderDesc, setOrderDesc] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const lastBook = useVisibility(
    (visible) => {
      if (visible) {
        if(text == "")
        {
          GetOrganizationTrim(skip, limit, orderDesc).then((newOrg) => {
            if (newOrg.length < limit) {
              setOrgMax(false);
            }
            setSkip(skip + newOrg.length);
            setOrg([...org, ...newOrg]);
          });
        }
        else
        {
          GetOrganizationTrimName(skip, limit, orderDesc, text).then((newOrg) => {
            if (newOrg.length < limit) {
              setOrgMax(false);
            }
            setSkip(skip + newOrg.length);
            setOrg([...org, ...newOrg]);
          });
        }
      }
    },
    [skip, org]
  );

  useEffect(() => {
    setSkip(0);
    setOrg([]);
    setOrgMax(true);
    if(text == "")
    {
      GetOrganizationTrim(0, limit, orderDesc).then((newOrg) => {
        if (newOrg.length < limit) {
          setOrgMax(false);
        }
        setSkip(newOrg.length);
        setOrg(newOrg);
      });
    }
    else
    {
      GetOrganizationTrimName(0, limit, orderDesc, text).then((newOrg) => {
        if (newOrg.length < limit) {
          setOrgMax(false);
        }
        setSkip(newOrg.length);
        setOrg(newOrg);
      });
    }
  }, [text, orderDesc]);

  const asc: IIconProps = {
    iconName: 'Up',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const desc: IIconProps = {
    iconName: 'Down',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const ascClick = () => {
    setOrderDesc(false)
  };

  const descClick = () => {
    setOrderDesc(true)
  };


  return (
    <div>
      <ul className={styles.ulContent} >
        <li className={`${styles.itemContent} ${styles.title}`}>
            {t("NomeOrg.message")}{" "}
            {orderDesc?
            (<IconButton iconProps={asc} title="Ascendente" ariaLabel="Ascendente" onClick={ascClick}/>):
            (<IconButton iconProps={desc} title="Descendente" ariaLabel="Descendente" onClick={descClick}/>)}
          </li>
          {org.map((orgC) => (
            <OrgItem
              key={orgC.id}
              nameOrg={orgC.internalName}
              name={orgC.name}
              ref={org[org.length - 1].id === orgC.id && orgMax ? lastBook : null}
            />
          ))}
      </ul>
      <div>
        {orgMax === false ? null : null}
      </div>
    </div>
  );
};

export default OrganizationListComp;
