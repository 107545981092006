import React, { useEffect, useState } from "react";
import { WorkSpace} from "../../../model/WorkSpaceModel";
import { 
  GetOrgWorkSpaceAdmTrim
 } from "../../../services/WorkSpaceServices";
import { useNavigate } from "react-router-dom";
import stylesList from "../../Style/List.module.css";
import { IconButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react';
import { useTranslation } from "react-i18next";
import {
  GetOrganizationWorkspaceAdmTrimName
} from "../../../services/OrganizationServices";

export interface WorkSpaceListCompConfig {
  skip: number;
  id: string | undefined;
}

export interface DropdownMenuContextoConfig{
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
}
interface WorkItemProps {
  id?: string;
  nameOrg: string;
  name?: string;
  hiddenContexMenu: boolean;
  ref?: React.Ref<HTMLLIElement>;
}

interface ListWorkId {
  nameOrg: string;
  name: string;
  onClickEditar?: React.MouseEventHandler<HTMLElement> | undefined;
  onClickExcluir?: React.MouseEventHandler<HTMLElement> | undefined;
  hiddenContexMenu: boolean;
}

const WorkSpaceListCompAdm: React.FC<ListWorkId> = ({nameOrg, name, hiddenContexMenu}) => {
  const [workSpace, setWork] = useState<WorkSpace[]>([]);
  const navegate = useNavigate();
  const [limit, setLimit] = useState<number>(50);

  let [orderDesc, setOrderDesc] = useState<boolean>(false);
  let [skip, setSkip] = useState<number>(0);
  let [WorkMax, setWorkMax] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  const WorkItem: React.ForwardRefExoticComponent<WorkItemProps> =
  React.forwardRef(({ id, name, hiddenContexMenu, nameOrg }, ref: React.Ref<HTMLLIElement>) => {
    function redirect(){

      navegate("/" + nameOrg + "/temporality/" + id);
    }

    return (
      <div>
        <li className={`${stylesList.itemContent} ${stylesList.row} ${stylesList.pointer}`} key={id} ref={ref} onClick={redirect}>
          {name}{" "}     
        </li>
      </div>
    );
  });

  useEffect(() => {
    if(name == "")
    {
      GetOrgWorkSpaceAdmTrim(nameOrg, skip, limit, orderDesc).then((newWork) => {
        if (newWork.length < limit) {
          setWorkMax(false);
        }
        setSkip(newWork.length);
        setWork(newWork);
      });
    }
    else
    {
      GetOrganizationWorkspaceAdmTrimName(nameOrg, skip, limit, orderDesc, name).then((newWork) => {
        if (newWork.length < limit) {
          setWorkMax(false);
        }
        setSkip(newWork.length);
        setWork(newWork);
      });
    }
  }, [nameOrg,name, orderDesc]);

  const asc: IIconProps = {
    iconName: 'Down',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const desc: IIconProps = {
    iconName: 'Up',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const ascClick = () => {
    setOrderDesc(true)
  };

  const descClick = () => {
    setOrderDesc(false)
  };

return (
  <div>
    <ul className={stylesList.ulContent}>
      <li className={`${stylesList.itemContent} ${stylesList.title}`}>
         <>{t("Nome.workspace")}</>{" "}
          {orderDesc?
          (<IconButton iconProps={desc} title="Descendente" ariaLabel="Descendente" onClick={descClick}/>):
          (<IconButton iconProps={asc} title="Ascendente" ariaLabel="Ascendente" onClick={ascClick}/>)}
        </li>
      {workSpace.map((workSpaceC) => (
        <WorkItem
          key={workSpaceC.id}
          id={workSpaceC.id}
          nameOrg={nameOrg}
          name={workSpaceC.name}
          hiddenContexMenu={hiddenContexMenu}
        />
      ))}
    </ul>
    <div>
      {WorkMax === false ? 
      null
      : null}
      </div>
  </div>
);
}
export default WorkSpaceListCompAdm;
