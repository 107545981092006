import React, { useEffect, useState } from "react";
import { trackerItemVO } from "../../../../model/ItemModel";
import { OrgInfoTrackerListItem } from "./components/OrgInfoTrackerListItem";
import { OrgInfoTrackerListingHeader } from "../OrgInfoTrackerListingHeader/OrgInfoTrackerListingHeader";
import LoaderComp from "../../../../components/Loader/LoaderComp";
import { AlertColor, Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { GetAllRecentTracker } from "../../../../services/Item/ItemServicesGet";
import { NoTrackerView } from "./NoTrackerView";

interface IOrgInfoTrackerListing {
    setUserError: (set: boolean) => void;
    setSeverity: (set: AlertColor) => void;
    setPopupMessage: (set: string | undefined) => void;
}

export const OrgInfoTrackerListing: React.FunctionComponent<IOrgInfoTrackerListing> = ({
    setUserError,
    setPopupMessage,
    setSeverity
}) => {

    const { nameOrg } = useParams();
    const [trackersLoading, setTrackersLoading] = useState<boolean>(false);
    const [trackers, setTrackers] = useState<trackerItemVO[]>([]);


    useEffect(() => {
        setUserError(false);
        if (nameOrg != undefined) {
          setTrackersLoading(true);
          GetAllRecentTracker(nameOrg)
          .then(data => {
            setTrackers(data)
          })
          .catch(err => {
            setPopupMessage(err.response.data);
            setSeverity("error");
          })
          .finally(() => {
            setTrackersLoading(false)
          })
        }
      }, [nameOrg])

    return (
        <>
            { trackers.length > 0 && (
                <OrgInfoTrackerListingHeader />
            ) }
            { trackersLoading ? (
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50%"
                }}>
                    <LoaderComp />
                </Box>
            ) : (
                <>
                    { trackers.length > 0 ? (
                        trackers.map(({
                            dateCreateTracker,
                            emailUser,
                            user,
                            message,
                            id,
                            itemExtension,
                            nameItem
                        }) => (
                            <OrgInfoTrackerListItem 
                                dateCreateTracker={dateCreateTracker!}
                                emailUser={emailUser!}
                                itemExtension={itemExtension!}
                                message={message!}
                                nameItem={nameItem!}
                                user={user!}
                                key={id!}
                            />
                        ))
                    ) : (
                        <NoTrackerView />
                    ) }
                </>
            ) }
        </>
    )
}