import { Box, Grid, Typography } from "@mui/material"
import { RecentAct } from "../../../../../assets/svg/recentAct"
import { useTranslation } from "react-i18next"

export const OrgInfoActivitiesHeader = () => {

    const { t } = useTranslation();

    return (
        <Grid item xs={12} mb="20px">
            <Box sx={{
                display: "flex",
            }}>
            <RecentAct />
            <Typography sx={{
                textAlign: "left",
                font: "normal normal bold 18px/27px Segoe UI",
                letterSpacing: "0px",
                color: "#2D2D2D",
                opacity: 1,
                ml: "12px"
            }}>
                {t("LoggedHome.RecentActivities")}
            </Typography>
            </Box>
            <Box sx={{
            mt: "8px",
            mb: "8px"
            }}>
            <Typography sx={{
                textAlign: "left",
                letterSpacing: "0px",
                color: "#969696",
                opacity: 1,
                fontSize: "14px"
            }}>
                {t("LoggedHome.RecentActivitiesContent")}
            </Typography>
            </Box>
      </Grid>
    )
}