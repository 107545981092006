import { useState } from 'react';
import { Grid, Button, Box } from '@mui/material';
import LoaderComp from '../../../../../../../../../../components/Loader/LoaderComp';
import { useTranslation } from 'react-i18next';

interface IDocumentDetailPhButtons
{
    updatePositionsHandler(): void;
    isLoading: boolean;
    cancelModifications: () => void;
    widthHandler: string;
}

export default function DocumentDetailPhButtons({
    updatePositionsHandler,
    isLoading,
    cancelModifications,
    widthHandler
}: IDocumentDetailPhButtons)
{
    const { t } = useTranslation();
    const [variantions, setVariations] = useState<'text' | 'outlined'>('text');

    return (
        <Grid container width={widthHandler} justifyContent='end' gap={1}>
            <Grid item>
                <Button
                    variant={variantions}
                    sx={{
                        transition: 'all 0.3s',
                    }}
                    onMouseEnter={() => setVariations('outlined')}
                    onMouseLeave={() => setVariations('text')}
                    onClick={cancelModifications}
                >
                    {t("DetailPhLocation.CancelButton")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant='contained'
                    onClick={updatePositionsHandler}
                >
                    { isLoading ? <LoaderComp /> : t("DetailPhLocation.SaveButton") }
                </Button>
            </Grid>            
        </Grid>
    )
}