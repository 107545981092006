import { useTranslation } from "react-i18next";
import Btn from "../../../components/Button/Btn";
import LoaderComp from "../../../components/Loader/LoaderComp";
import Message from "../../../components/Message/Message";
import { ITag, MessageBarType, Shimmer, ShimmerElementType } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { CloneWorkspace, GetWorkSpaceUser } from "../../../services/WorkSpaceServices";
import { WorkSpace, WorkSpaceUser } from "../../../model/WorkSpaceModel";
import TextFieldComp from "../../../components/TextField/TextFieldComp";

export interface IWorkspaceClone {
    nameOrg: string;
    idWork?: string;
    saveOkMetod: () => void;
}

export function WorkspaceClone({
  saveOkMetod,
  nameOrg,
  idWork
}: IWorkspaceClone) {

    const { t } = useTranslation();
    const [isMessageOk, setMessageOk] = useState<boolean>(false);
    const [isMessageError, setMessageError] = useState<boolean>(false);
    const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));
    const [nameChanged, setNameChanged] = useState<string>("");
    const [initialsChanged, setInitials] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [waitGet, setWaitGet] = useState<boolean>(false);
    const [popupOk, setPopupOk] = useState<boolean>(false);
    const [workspaceConst, setWorkspaceConst] = useState<WorkSpaceUser | null>(null);
    

  useEffect(() => {
    console.log(idWork)
    getWorkspace(idWork!)
  }, [])
  
  function getWorkspace(idWork:string)
  {
    setWaitGet(true);
    GetWorkSpaceUser(idWork)
    .then((workspace) => {
      if (workspace != null) {

        setWorkspaceConst(workspace);

        if (workspace.name) {
          setNameChanged(workspace.name);
        }
        
        if(workspace.initials){
          setInitials(workspace.initials);
        } 
      } else {
        console.log("Não encontrado o Workspace");
        setPopupOk(true);
      }
    })
    .catch(() => {
      console.log("Não encontrado o Workspace");
      setPopupOk(true);
    })
    .finally(() =>{
      setWaitGet(false);
    });
  }


    function resetMessage() {
        setMessageOk(false);
        setMessageError(false);
    }

    function cloneWorkSpace() {
        resetMessage();
        if(nameChanged.length < 3)
        {
          setMessageErrorString(t("MinDeCaracteres.message"));
          setMessageError(true);
          setLoader(false);
          return;
        }
        else if(nameChanged.length > 50){
          setMessageErrorString(t("MaxDeCaracteres.message"));
          setMessageError(true);
          setLoader(false);
          return;
        }
        if (workspaceConst != null) {
          let itemModelo: WorkSpace = {};
          itemModelo.id = workspaceConst.id;
          itemModelo.name = nameChanged;
          itemModelo.initials = initialsChanged;
          CloneWorkspace(itemModelo).then(() => {
            setMessageOk(true);
            saveOkMetod();
          }).catch((error) =>{
            if( error.response && error.response.data && typeof error.response.data  === 'string'){
              setMessageErrorString(error.response.data);
            }
            setMessageError(true);
          })
          .finally(() => {
            setLoader(false);
          });
        }
      }

      const onChangeSetName = React.useCallback(
        (
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          if (newValue != undefined) {
            setNameChanged(newValue);
          }
          else
          {
            setNameChanged("");
          }
        },
        []
      );

      const onChangeSetInitials = React.useCallback(
        (
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          if (newValue != undefined) {
            setInitials(newValue);
          }
          else
          {
            setInitials("");
          }
        },
        []
      );

      const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key ===  'Enter'){
          cloneWorkSpace();
        }
      }


    return (
        <>
          {waitGet?(
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, height: 28 }
              ]}
              style={{
                marginTop:"5px",
                marginBottom:"5px"
              }}
            />
            ):(
            <TextFieldComp
              label={t("Nome.workspace")}
              value={nameChanged}
              onChange={onChangeSetName}
              autofocus
              onKeyDown={onKeyDown}
            />
          )}

          {waitGet?(
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, height: 28 }
              ]}
              style={{
                marginTop:"5px",
                marginBottom:"5px"
              }}
            />
          ):(
            <TextFieldComp
              label={t("Iniciais.message")}
              value={initialsChanged}
              onChange={onChangeSetInitials}
              onKeyDown={onKeyDown}
            />
          )}
            {loader && (
                <LoaderComp/>
            )}
  
            {isMessageError && (
                <Message text={messageErrorString!} type={MessageBarType.error} />
            )}
    
            {isMessageOk && (
                <Message text={t("WorkSalvo.message")} type={MessageBarType.success} />
            )}

            <Btn text={t("Clone.message")} onClick={cloneWorkSpace}/>  
        </>
    )
}