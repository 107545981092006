import React, { useState, useEffect } from "react";
import {
  Edit,
  GetWorkSpaceUser,
  CreateWorkSpace,
  CloneWorkspace
} from "../../services/WorkSpaceServices";
import {
  GetOrganizationUserName
} from "../../services/OrganizationServices";
import { WorkSpace, WorkSpaceUser,  WorkSpaceCreate,PermissionActor, User } from "../../model/WorkSpaceModel";
import Btn from "../../components/Button/Btn";
import MessageComp from "../../components/Dialog/Message";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import CheclkBoxComp  from "../../components/Checkbox/CheckboxComp";
import { useNavigate, useParams } from "react-router-dom";
import { Separator, } from "@fluentui/react/lib/Separator";
import I18n from '../../components/I18n/I18n';
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Message from "../../components/Message/Message";
import {MessageBarType } from "@fluentui/react";

import { TagPicker, ITag, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { useId } from '@fluentui/react-hooks';

import { IStackStyles, } from '@fluentui/react/lib/Stack';
import { CompressOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Organization } from "../../model/OrganizationModel";
import { Label, Pivot, PivotItem } from '@fluentui/react';
import { List , IList} from '@fluentui/react/lib/List';
import { IRectangle } from '@fluentui/react/lib/Utilities';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { useConst } from '@fluentui/react-hooks';
import { ContextualMenuItemType, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import stylesPivot from "../../components/Pivot/Pivot.module.css";
import LoaderComp from '../../components/Loader/LoaderComp';
import { Box, Checkbox, FormControlLabel  } from "@mui/material";
import { Shimmer, ShimmerElementType, IShimmerElement, ThemeProvider } from '@fluentui/react';

export interface WorkSpaceConfigParams{
 nameOrg: string;
 idWork?: string;
 saveOkMetod: () => void
}

export interface ConfigParams{
  id: string;
  name: string;
  onAddCategory: (categoryText: string) => Promise<ConfigParams>;
  onChange: (items: ITag[] | undefined) => void;
 }

 const styles = mergeStyleSets({
  container: {
    overflow: 'auto',
    maxHeight: 100,
    border: '1px solid #CCC',
    selectors: {
      '.ms-List-cell:nth-child(odd)': {
        height: 50,
        lineHeight: 50
      },
      '.ms-List-cell:nth-child(even)': {
        height: 25,
        lineHeight: 25,
      },
    },
  },
});

const WorkSpaceConfig = (props: WorkSpaceConfigParams, config:ConfigParams) => {
  const { t, i18n } = useTranslation();
  const [workspaceConst, setWorkspaceConst] = useState<WorkSpaceUser | null>(null);
  const [groupPerm, setGroupPerm] = useState<PermissionActor[]>([]);
  const [userPerm, setUserPerm] = useState<PermissionActor[]>([]);

  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [nameChanged, setNameChanged] = useState<string>("");
  const [initialsChanged, setInitials] = useState<string>("");
  const [itensTag, setItensTag] = useState<ITag[] | undefined>([]);
  const [reaload, setReaload] = useState<boolean>(false);

  const [isMessageOk, setMessageOk] = useState<boolean>(false);
  const [isMessageError, setMessageError] = useState<boolean>(false);
  const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));

  const [loader, setLoader] = useState<boolean>(false);
  const [waitGet, setWaitGet] = useState<boolean>(false);

  const navegate = useNavigate();
  
  const [filter, setFilter] = useState<string>("");
  const listRef: React.RefObject<IList> = React.useRef(null);

  const [isCheckedUploadAsRelease, setIsCheckedUploadAsRelease] = React.useState(false);
  const [isCheckedOrganizerCannotDelete, setIsCheckedOrganizerCannotDelete] = React.useState(false);
  const [isCheckedEditorCannotDelete, setIsCheckedEditorCannotDelete] = React.useState(false);
  const [isCheckedEditorCannotSeeRevision, setIsCheckedEditorCannotSeeRevision] = React.useState(false);
  const [isCheckedAutomaticViwerDwg, setIsCheckedAutomaticViwerDwg] = React.useState(false);
  
  function changeCheckboxStatus(setIsChecked: React.Dispatch<React.SetStateAction<boolean>>,ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
    if(checked == undefined || checked == false)
    {
      setIsChecked(false);
    }
    else
    {
      setIsChecked(true);
    }
  };

  const titlePicker = mergeStyles({
    fontWeight:900,
    margin:"5px",
    fontFamily: "Microsoft YaHei, sans-serif",
    fontSize:"14px"
  });

  const rootClass: Partial<IStackStyles> = {
    root: {
        width:"100%",
        borderColor: "#114D88",
        margin:"5px",
    }
  };

  const pickers = mergeStyles({
    marginTop: "10px"
  });


  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("UsuariosSugeridos.message"),
    noResultsFoundText: t("UsuariosNaoEncontradors.message"),
  };

  const filterPromise = (selectedItems?: ITag[]): ITag[] | PromiseLike<ITag[]> => {
    return GetOrganizationUserName(props.nameOrg, filter)
    .then((users) => {
      const userTags: ITag[] = users.map(item => ({ key: item.id, name: item.name}));
      return userTags;
    })
    .catch(() =>{
      return [];
    });
  };


  const onResolveSuggestions = async (
    filter: string,
    selectedItems: ITag[] | undefined
  ): Promise<ITag[]> => {
    if (filter) {
      return GetOrganizationUserName(props.nameOrg, filter)
      .then((users) => {
        const userTags: ITag[] = users.map(item => ({ key: item.id, name: item.name}));
        return userTags;
      })
      .catch(() =>{
        return [];
      });
    }
    return [];
  };

  const onChange = (items: ITag[] | undefined) => {
    setItensTag(items);
  };

  const getTextFromItem = (item: ITag) => item.name;

  useEffect(() => {
    if(reaload == true ){
      setReaload(false)
    }
  }, [reaload]);

  useEffect(() => {
    if(reaload == false ){
      setReaload(true);
    }
  }, [itensTag]);


  useEffect(() => {
    if (props.idWork != undefined) {
      getWorkspace(props.idWork);
    }
  }, []);


  function getWorkspace(idWork:string)
  {
    setWaitGet(true);
    GetWorkSpaceUser(idWork)
    .then((workspace) => {
      if (workspace != null) {
        setWorkspaceConst(workspace);

        if(workspace.permissionListActors)
        {
          if(workspace.permissionListActors.groups)
            setGroupPerm(workspace.permissionListActors.groups);

            if(workspace.permissionListActors.users)
            setUserPerm(workspace.permissionListActors.users);
        }

        if (workspace.name) {
          setNameChanged(workspace.name);
        }
        
        if(workspace.initials){
          setInitials(workspace.initials);
        }

        if(workspace.adminUsers){
          let newitems: ITag[] =
          workspace.adminUsers.map(item => ({ key: item.id, name: item.name }));
          setItensTag(newitems);
        }

        if(workspace.isUploadAsRealease != undefined){
          setIsCheckedUploadAsRelease(workspace.isUploadAsRealease);
        }

        if(workspace.isOrganizerCannotDelete != undefined){
          setIsCheckedOrganizerCannotDelete(workspace.isOrganizerCannotDelete);
        }

        if(workspace.isEditorCannotDelete != undefined){
          setIsCheckedEditorCannotDelete(workspace.isEditorCannotDelete);
        }

        if(workspace.automaticViwerDwg != undefined){
          setIsCheckedAutomaticViwerDwg(workspace.automaticViwerDwg)
        }
        if (workspace.isEditorCannotSeeRevision != undefined) {
          setIsCheckedEditorCannotSeeRevision(workspace.isEditorCannotSeeRevision);
        }
          
      } else {
        console.log("Não encontrado o Workspace");
        setPopupOk(true);
      }
    })
    .catch(() => {
      console.log("Não encontrado o Workspace");
      setPopupOk(true);
    })
    .finally(() =>{
      setWaitGet(false);
    });
  }


  function closePopUpOk() {
    setPopupOk(false);
    if(props.nameOrg == undefined)
      navegate("/");
    else
      navegate("/"+ props.nameOrg + "/workspace/");
  }

  function createWorkSpace() {
    resetMessage();
    if(nameChanged.length < 3)
    {
      setMessageErrorString(t("MinDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    else if(nameChanged.length > 50){
      setMessageErrorString(t("MaxDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    let newOrg: WorkSpaceCreate = {
      name: nameChanged,
      initials: initialsChanged,
      organizationName: props.nameOrg,
      adminUsers: itensTag?.map(item => (item.key.toString())),
      isUploadAsRealease: isCheckedUploadAsRelease,
      isOrganizerCannotDelete: isCheckedOrganizerCannotDelete,
      isEditorCannotDelete: isCheckedEditorCannotDelete,
      automaticViwerDwg:isCheckedAutomaticViwerDwg,
      isEditorCannotSeeRevision: isCheckedEditorCannotSeeRevision
    };

    CreateWorkSpace(newOrg)
      .then((idResponse:string) => {
        props.saveOkMetod();
        setMessageOk(true);
      })
      .catch((error) => {
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setMessageErrorString(error.response.data);
        }
        setMessageError(true);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function editWorkSpace() {
    resetMessage();
    if(nameChanged.length < 3)
    {
      setMessageErrorString(t("MinDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    else if(nameChanged.length > 50){
      setMessageErrorString(t("MaxDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    if (workspaceConst != null) {
      let itemModelo: WorkSpace = {};
      itemModelo.id = workspaceConst.id;
      itemModelo.organizationName = props.nameOrg;
      itemModelo.name = nameChanged;
      itemModelo.initials = initialsChanged;
      itemModelo.adminUsers = itensTag?.map(item => (item.key.toString()));
      itemModelo.isUploadAsRealease = isCheckedUploadAsRelease;
      itemModelo.isOrganizerCannotDelete = isCheckedOrganizerCannotDelete;
      itemModelo.isEditorCannotDelete = isCheckedEditorCannotDelete;
      itemModelo.automaticViwerDwg = isCheckedAutomaticViwerDwg;
      itemModelo.isEditorCannotSeeRevision = isCheckedEditorCannotSeeRevision;
      Edit(itemModelo).then(() => {
        setMessageOk(true);
        // window.location.reload();
      }).catch((error) =>{
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setMessageErrorString(error.response.data);
        }
        setMessageError(true);
      })
      .finally(() => {
        setLoader(false);
      });
    }
  }

  function cloneWorkSpace() {
    resetMessage();
    if(nameChanged.length < 3)
    {
      setMessageErrorString(t("MinDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    else if(nameChanged.length > 50){
      setMessageErrorString(t("MaxDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    if (workspaceConst != null) {
      let itemModelo: WorkSpace = {};
      itemModelo.id = workspaceConst.id;
      itemModelo.name = nameChanged;
      itemModelo.initials = initialsChanged;
      CloneWorkspace(itemModelo).then(() => {
        setMessageOk(true);
      }).catch((error) =>{
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setMessageErrorString(error.response.data);
        }
        setMessageError(true);
      })
      .finally(() => {
        setLoader(false);
      });
    }
  }

  function crudWorkspace()
  {
    setMessageErrorString(t("ErroAoSalvar.message"));    
    resetMessage();
    if(workspaceConst?.id)
    {
      setLoader(true);
      editWorkSpace();   
    } 
    else
    {
      setLoader(true);
      createWorkSpace();
    }
  }


  function resetMessage() {
    setMessageOk(false);
    setMessageError(false);
  }

  const onChangeSetName = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        setNameChanged(newValue);
      }
      else
      {
        setNameChanged("");
      }
    },
    []
  );

  const onChangeSetInitials = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        setInitials(newValue);
      }
      else
      {
        setInitials("");
      }
    },
    []
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Excluir",
    closeButtonAriaLabel: "Excluir",
  };  
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key ===  'Enter'){
      crudWorkspace();
    }
  }

  const onRenderCell = (item?: PermissionActor, index?: number, isScrolling?: boolean): JSX.Element => {
    return (
      <div data-is-focusable>
        {item?.nameActor}
      </div>
    );
  };

  

  const permissionGroup = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem)  => {
    if(ev)
      ev.preventDefault();

    if(workspaceConst?.id)
    {
      navegate("/" + props.nameOrg + "/WorkSpace/" + workspaceConst?.id + "/Permission");
    }
  }

  const permissionUser = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem)  => {
    if(ev)
      ev.preventDefault();
    
    if(workspaceConst?.id)
    {
      console.log("user");
      navegate("/" + props.nameOrg + "/WorkSpace/" + workspaceConst?.id + "/UserPermission");
    }
  }

  return (
    <div>
      <h3>{t("ConfigWork.message")}</h3>
      {waitGet?(
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 28 }
          ]}
          style={{
            marginTop:"5px",
            marginBottom:"5px"
          }}
        />
        ):(
        <TextFieldComp
          label={t("Nome.workspace")}
          value={nameChanged}
          onChange={onChangeSetName}
          autofocus
          onKeyDown={onKeyDown}
        />
      )}

      {waitGet?(
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 28 }
          ]}
          style={{
            marginTop:"5px",
            marginBottom:"5px"
          }}
        />
      ):(
        <TextFieldComp
          label={t("Iniciais.message")}
          value={initialsChanged}
          onChange={onChangeSetInitials}
          onKeyDown={onKeyDown}
        />
      )}

          <>
            {waitGet?(
              <Shimmer
                shimmerElements={[
                  { type: ShimmerElementType.line, height: 28 }
                ]}
                style={{
                  marginTop:"5px",
                  marginBottom:"5px"
                }}
              />
            ):(
      
            <div className={pickers}>
              <label  className={titlePicker}>{t("UsuariosAdm.message")}</label>
              {reaload == false&&(
                <TagPicker
                  removeButtonAriaLabel="Remove"
                  selectionAriaLabel="People"
                  onResolveSuggestions={onResolveSuggestions}
                  getTextFromItem={getTextFromItem}
                  pickerSuggestionsProps={pickerSuggestionsProps}
                  removeButtonIconProps={{ iconName: 'Delete' }}
                  pickerCalloutProps={{ doNotLayer: true }}
                  styles={rootClass}
                  onChange={onChange}
                  defaultSelectedItems={itensTag}
                  onEmptyInputFocus={filterPromise}
                />
                )}
      
              </div> 
            )}
      
            {waitGet?(
              <Shimmer
                shimmerElements={[
                  { type: ShimmerElementType.line, height: 28 }
                ]}
                style={{
                  marginTop:"5px",
                  marginBottom:"5px"
                }}
              />
            ):
              workspaceConst?.id && (
                <Pivot linkFormat="links"
                  styles={{
                    linkIsSelected:{
                      backgroundColor:"rgb(17, 77, 136)",
                      color:"rgb(248, 248, 248)",
                      selectors: {
                        ':before': {
                          height: '0px',
                        }
                      }
                    },
                    link:{
                      border:"1px solid rgb(17, 77, 136)",
                      backgroundColor:"rgb(248, 248, 248)",
                      color:"rgb(17, 77, 136)",
                      selectors: {
                        ':hover': {
                          backgroundColor:"#ebebeb",
                        }
                      }
                    }
                  }}
                >
                  <PivotItem
                    headerText="Groups"
                    itemIcon="People"            
                  >
                  <div className={styles.container} data-is-scrollable>
                    <List
                      componentRef={listRef}
                      items={groupPerm}
                      onRenderCell={onRenderCell}
                    />
                    </div>
                  </PivotItem>
                  <PivotItem
                    headerText="Users"
                    itemIcon="Contact"
                  >
                  <div className={styles.container} data-is-scrollable>
                    <List
                      componentRef={listRef}
                      items={userPerm}
                      onRenderCell={onRenderCell}
                    />
                    </div>
                  </PivotItem>
                </Pivot>
            )}
      
            {waitGet?(
              <Shimmer
                shimmerElements={[
                  { type: ShimmerElementType.line, width: 32, height: 32 },
                  { type: ShimmerElementType.gap, width: 5},
                  { type: ShimmerElementType.line, height: 15 }
                ]}
                style={{
                  marginTop:"5px",
                  marginBottom:"5px"
                }}
              />
            ):(
              <CheclkBoxComp 
                label={t("UploadAsRelease.message")}
                checked={isCheckedUploadAsRelease}
                onChange={(ev, checked) => changeCheckboxStatus(setIsCheckedUploadAsRelease, ev, checked)}
              />
            )}
      
            {waitGet?(
              <Shimmer
                shimmerElements={[
                  { type: ShimmerElementType.line, width: 32, height: 32 },
                  { type: ShimmerElementType.gap, width: 5},
                  { type: ShimmerElementType.line, height: 15 }
                ]}
                style={{
                  marginTop:"5px",
                  marginBottom:"5px"
                }}
              />
            ):(
              <CheclkBoxComp 
                label={t("OrganizerCannotDelete.message")}
                checked={isCheckedOrganizerCannotDelete}
                onChange={(ev, checked) => changeCheckboxStatus(setIsCheckedOrganizerCannotDelete, ev, checked)}
              />
            )}
      
            {waitGet?(
              <Shimmer
                shimmerElements={[
                  { type: ShimmerElementType.line, width: 32, height: 32 },
                  { type: ShimmerElementType.gap, width: 5},
                  { type: ShimmerElementType.line, height: 15 }
                ]}
                style={{
                  marginTop:"5px",
                  marginBottom:"5px"
                }}
              />
            ):(
              
              <>
                <CheclkBoxComp 
                  label={t("EditorCannotDelete.message")}
                  checked={isCheckedEditorCannotDelete}
                  onChange={(ev, checked) => changeCheckboxStatus(setIsCheckedEditorCannotDelete, ev, checked)}
                />
                <CheclkBoxComp 
                  label={t("EditorCannotSeeRevision.message")}
                  checked={isCheckedEditorCannotSeeRevision}
                  onChange={(ev, checked) => changeCheckboxStatus(setIsCheckedEditorCannotSeeRevision, ev, checked)}
                />
              </>
              
            )}

              <CheclkBoxComp 
                label={t("ViwerAutomaticDwg.message")}
                checked={isCheckedAutomaticViwerDwg}
                onChange={(ev, checked) => changeCheckboxStatus(setIsCheckedAutomaticViwerDwg, ev, checked)}
              />
      
            {loader && (
              <LoaderComp/>
            )}
       
            {isMessageError && (
              <Message text={messageErrorString} type={MessageBarType.error} />
            )}
      
            {isMessageOk && (
              <Message text={t("WorkSalvo.message")} type={MessageBarType.success} />
            )}
      
            <Btn text={t("Salvar.message")} onClick={crudWorkspace}/>   
      
            {workspaceConst?.id && (
              <Btn text={t("Upload.permission")} menuProps={{
                shouldFocusOnMount: true,
                items:[
                  {
                    key:"group",
                    text:t("Upload.permissionGroup"),
                    onClick: permissionGroup
                  },
                  {
                    key:"user",
                    text:t("Upload.permissionUser"),
                    onClick: permissionUser
                  }
                ]
              }}/>
            )} 
          </>

    </div>
  );
};

export default WorkSpaceConfig;
