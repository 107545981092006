interface IWorkspaceIcon {
    color: string;
    width: string;
    height: string;
}

export const WorkspaceIcon: React.FunctionComponent<IWorkspaceIcon> = ({
    color = "#22638d",
    width,
    height
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 18">
        <path id="workspaces_FILL1_wght400_GRAD0_opsz24" d="M84-822a3.852,3.852,0,0,1-2.825-1.175A3.851,3.851,0,0,1,80-826a3.852,3.852,0,0,1,1.175-2.825A3.852,3.852,0,0,1,84-830a3.852,3.852,0,0,1,2.825,1.175A3.852,3.852,0,0,1,88-826a3.851,3.851,0,0,1-1.175,2.825A3.852,3.852,0,0,1,84-822Zm12,0a3.852,3.852,0,0,1-2.825-1.175A3.851,3.851,0,0,1,92-826a3.852,3.852,0,0,1,1.175-2.825A3.852,3.852,0,0,1,96-830a3.852,3.852,0,0,1,2.825,1.175A3.852,3.852,0,0,1,100-826a3.851,3.851,0,0,1-1.175,2.825A3.852,3.852,0,0,1,96-822Zm-6-10a3.852,3.852,0,0,1-2.825-1.175A3.851,3.851,0,0,1,86-836a3.852,3.852,0,0,1,1.175-2.825A3.852,3.852,0,0,1,90-840a3.852,3.852,0,0,1,2.825,1.175A3.852,3.852,0,0,1,94-836a3.851,3.851,0,0,1-1.175,2.825A3.852,3.852,0,0,1,90-832Z" transform="translate(-80 840)" fill={color}/>
    </svg>
)