import React from "react";
import stylesList from "../../Style/List.module.css";
import moment from "moment";
import styles from '../LogErrorsList.module.css'
import { LogError } from "../../../model/LogError";
import { Tooltip } from "@mui/material";

interface TemporalityListCompProps {
    logError: LogError,
    ref?: React.Ref<any>
    lastLine: boolean
    firstLine: boolean
}

  const GridArchive: React.ForwardRefExoticComponent<TemporalityListCompProps> =
  React.forwardRef(({ logError, lastLine, firstLine }, ref:  React.Ref<any>) => {

    var dataTemporality = moment(logError.createdDate).format('DD/MM/YYYY');
    return (
      <ul className={stylesList.ulContent} >
        <li style={{ borderBottomLeftRadius: lastLine ? "8px" : "", borderBottomRightRadius: lastLine ? "8px" : "", marginBottom: lastLine ? "25px": ""}} className={styles.gridTemporalityBodyLi} ref={ref}>
         <div className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={logError.browser}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {logError.browser}
              </span>
            </Tooltip>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={logError.errorFound}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {logError.errorFound}
              </span>
            </Tooltip>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={dataTemporality}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {dataTemporality}
              </span>
            </Tooltip>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={logError.server}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {logError.server}
              </span>
            </Tooltip>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={logError.method}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {logError.method}
              </span>
            </Tooltip>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={logError.stackTrace}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {logError.stackTrace}
              </span>
            </Tooltip>
          </div>
          <div className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={logError.userMail}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {logError.userMail}
              </span>
            </Tooltip>
          </div>
          <div style={{ borderRight: "0px" }} className={styles.gridTemporalityBodyDiv}>
            <Tooltip
              title={logError.line}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span className={styles.gridTemporalitySpan}>
                {logError.line}
              </span>
            </Tooltip>
          </div>
        </li>
      </ul>
    );   
  });
  
  export default GridArchive;