import React from "react";
import { ItemValidationRule, WorkSpaceUserName } from "../../../../model/ItemModel"
import BackAndFowardComponent from "./BackAndFowardComponent";
import EmptyRule from './components/EmptyRule';
import StartButton from "./components/StartButton"
import { GetOrganizationUser, GetOrganizationUserName } from "../../../../services/OrganizationServices";
import { useProfile } from '../../../../context/ProfileProvider/useProfile';
import { BackItem, ForwardItem, ValidationPreconditionBackItem, ValidationPreconditionFoward } from "../../../../services/ValidationRuleService";
import ActionsButton from './components/ActionsButton';
import { AlertColor } from "@mui/material";
import { validationRule } from "../../WorkSpaceItemListStyle/ColumnStandard";

interface IValidationRuleButton {
    unvalidType: boolean,
    respValidationRule?: boolean,
    isValidateReadViwer: boolean;
    workspaceItem: WorkSpaceUserName;
    indexOfArray: number;
    nameOrg: string;
    setPopupError: (set: boolean, message: string) => void;
    setWorkspace: ((workSpaceUserName: WorkSpaceUserName, index: number, isUpload: boolean) => void);
    setValidationRule: (itemValidationRule: ItemValidationRule | undefined, index: number, isUpload: boolean) => void;
    isUpload: boolean;
    setPopupOk: (set: boolean, message: string) => void;
    idWorkspace: string;
}

const ValidationRuleButton: React.ForwardRefExoticComponent<IValidationRuleButton> = React.forwardRef(({
    respValidationRule,
    isValidateReadViwer,
    workspaceItem,
    indexOfArray,
    nameOrg,
    setPopupError,
    setWorkspace,
    isUpload,
    setPopupOk,
    idWorkspace,
    unvalidType
} , ref) => {

    const profile = useProfile();

    const isStarted = 
        unvalidType == false &&
        workspaceItem.isRelease != true &&
        workspaceItem.validationRule == undefined &&
        respValidationRule == false &&
        workspaceItem.itemType == "file";
        
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridArea: validationRule }}>

            {workspaceItem?.archiving != true && (isValidateReadViwer == false || (isValidateReadViwer && respValidationRule)) ? (
                <div style={{ gridArea: validationRule }}>
                  { workspaceItem?.itemType === "folder" && (
                    <EmptyRule />
                  ) }
                  <div>
                    <>
                        {isStarted && (
                            <StartButton 
                                indexOfArray={indexOfArray}
                                idWorkspace={idWorkspace}
                                setWorkspace={setWorkspace}
                                isUpload={isUpload}
                                workspaceItem={workspaceItem}
                            />
                        )}

                        {!isStarted && respValidationRule == true && !workspaceItem.isRelease && profile.name && profile.login && (
                            <BackAndFowardComponent 
                                FbComponent={ActionsButton}
                                workspaceItem={workspaceItem}
                                indexOfArray={indexOfArray}
                                setPopupError={setPopupError}
                                setWorkspace={setWorkspace}
                                isUpload={isUpload}
                                userFilter={nameOrg}
                                setPopupOk={setPopupOk}
                                userPromisse={GetOrganizationUser}
                                userPromisseWords={GetOrganizationUserName}
                                signer_display_name={profile.name}
                                signer_email={profile.login}
                                backItem={BackItem}
                                forwardItem={ForwardItem}
                                validationPreconditionBackItem={ValidationPreconditionBackItem}
                                validationPreconditionFoward={ValidationPreconditionFoward}
                            />
                        )}
                        
                    </>
                  </div>
                </div>
            ) : (
                <EmptyRule />
            )}
        </div> 
    )
})

export default ValidationRuleButton;