import { ProSidebar } from "react-pro-sidebar";
import styles from '../../../../../Documentdetail.module.css';
interface IFlowSidebarWrapper 
{
    isSidebarCollapsed: boolean;
    children: React.ReactNode;
    fullScreenSetted: boolean;
    isNotebookWidth: boolean;
}

export default function FlowSidebarWrapper({
    isSidebarCollapsed,
    children,
    fullScreenSetted,
    isNotebookWidth
}: IFlowSidebarWrapper)
{


    function sideBarHeightHandle() {
        if (fullScreenSetted) {
            return styles.sideBarStylesFull
        }

        if (isNotebookWidth) {
            return styles.sideBarStylesNotebook
        }

        return styles.sideBarStyles
    }

    return (
        <ProSidebar 
            collapsed={isSidebarCollapsed} 
            className={sideBarHeightHandle()}
            collapsedWidth={80}
            width={isNotebookWidth ? 400 : 550}
        >
            { children }
        </ProSidebar>
    )
}