import React from 'react';

interface ArrowIconProps {
  rotation: number;
  color: string;
}

const ArrowIcon: React.FunctionComponent<ArrowIconProps> = ({ rotation, color }) => {
  const rotationStyle = {
    transform: `rotate(${rotation}deg)`,
    marginLeft: "8.3px"
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.4"
      height="12"
      viewBox="0 0 7.4 12"
      style={rotationStyle}
    >
      <path
        id="keyboard_arrow_down_24dp_FILL1_wght400_GRAD0_opsz24"
        d="M246-632.6l-6-6,1.4-1.4,4.6,4.6,4.6-4.6,1.4,1.4Z"
        transform="translate(640 252) rotate(-90)"
        fill={color}
        opacity="0.5"
      />
    </svg>
  );
};

export default ArrowIcon;
