import React, {createContext, useEffect, useState} from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IAuthProvider, IContext, IUser } from './types';
import { GetUserLocalStorage, LoginRequest, LoginRequestMS, SetUserLocalStorage, RemoveUserLocalStorage } from "./util";

import {ProfileProvider} from '../ProfileProvider/';
import { useProfile } from "../ProfileProvider/useProfile";


export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {    
    const profile = useProfile();

    const[user, setUser] = useState<IUser|undefined>(()=>{
        const user = GetUserLocalStorage();
        if((user!= undefined && user.token != undefined) == false)
        {
            RemoveUserLocalStorage();
            return null;
        }

        if(user){
            return(user);
        }
    });

    async function authenticate (email:string, password:string) {
        LoginRequest(email, password)
        .then((response)=>{
            const payload = {token: response.data.access_token, email };

            setUser(payload);
            SetUserLocalStorage(payload);
        })
        .catch(()=>{
            throw console.error("Sem senha");
        });
    };

    async function authenticateMs(token: string) {
        LoginRequestMS(token)
        .then((response)=>{
            const payload = {token: response.data.access_token, email: response.data.email };

            setUser(payload);
            SetUserLocalStorage(payload);
        })
        .catch(()=>{
            throw console.error("Sem token");
        });
    }

    async function setUserExternal (user: IUser | undefined) {
        if(user)
        {
            setUser(user);
            SetUserLocalStorage(user);
        }
    };

    async function logout() {
        setUser(undefined);
        RemoveUserLocalStorage();
        if(profile.remove)
        {
            profile.remove();
        }
    };

    return (
        <AuthContext.Provider value={{...user, authenticate, setUserExternal, logout, authenticateMs}}>
            <ProfileProvider>
                {children}
            </ProfileProvider>
        </AuthContext.Provider>
    );
}