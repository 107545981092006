import { useTranslation } from 'react-i18next';
import styles from '../../../WorkSpaceItemList.module.css';
import { validationRule } from "../../../WorkSpaceItemListStyle/ColumnStandard";

export default function EmptyRule() {

    const { t } = useTranslation();

    return (
        <div style={{ gridArea: validationRule, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span className={styles.emptySlot}>{t("WithoutRule.withoutRule")}</span>
        </div>
    )
}