import React, { useState, useEffect } from "react";
import {
  GetOneGroup,
  CreateGroup,
  EditGroup,
} from "../../services/GroupsServices";
import { Group } from "../../model/GroupModel";
import Btn from "../../components/Button/Btn";
import MessageComp from "../../components/Dialog/Message";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { Separator } from "@fluentui/react/lib/Separator";
import { useBoolean } from "@fluentui/react-hooks";
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Message from "../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import {
  GetOneOrganization
} from "../../services/OrganizationServices";
import { Organization } from "../../model/OrganizationModel";
import { useNavigate } from "react-router-dom";
import LoaderComp from '../../components/Loader/LoaderComp';
import { useTranslation } from "react-i18next"; 

export interface GroupsConfigParameters {
  nameOrg: string,
  idGroup?: string
  saveOkMetod: () => void,
}


const GroupsConfig = (props: GroupsConfigParameters) => {
  const [org, setOrg] = useState<Organization | null>(null);
  const [group, setGroup] = useState<Group | null>(null);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [nameChanged, setNameChanged] = useState<string>("");
  const [isMessageError, setMessageError] = useState<boolean>(false);
  const [isMessageOk, setMessageOk] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>(t("ErroAoSalvar.message"));

  useEffect(() => {
    if (props.nameOrg != undefined)
    {
      GetOneOrganization(props.nameOrg).then((org) => {
        if (org != null) {
          setOrg(org);
      }})
      .catch(() => {
        console.log("Não encontrado a organização");
      }); 
    }
    if (props.idGroup != undefined) {
        getGroup(props.idGroup);
    }
  }, []);

  function getGroup(idGroup:string)
  {
    GetOneGroup(idGroup)
        .then((groupFound) => {
          if (groupFound != null) {
            setGroup(groupFound);
            if (groupFound.name) {
              setNameChanged(groupFound.name);
            }
          } else {
            console.log("Não encontrada o grupo");
          }
        })
        .catch(() => {
          console.log("Não encontrada o grupo");
        });
  }

  function crudGroup()
  {
    resetMessage();
    if(group?.id)
    {
      setLoader(true);
      editGroup();
    }
    else
    {
      setLoader(true);
      createGroup();
    }    
  }

  function createGroup() {
    resetMessage();
    if(nameChanged.length < 3)
    {
      setErrorMessage(t("MinDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    else if(nameChanged.length > 50){
      setErrorMessage(t("MaxDeCaracteres.message"));
      setMessageError(true);
      setLoader(false);
      return;
    }
    if (org != null) {
      let itemModelo: Group ={};
      itemModelo.name = nameChanged;
      itemModelo.organizationId = org?.id;
      CreateGroup(itemModelo).then((idResponse:string) => {
        props.saveOkMetod();
        setMessageOk(true);
      })
      .catch((error) =>{
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setErrorMessage(error.response.data);
        }
        setMessageError(true);
      })
      .finally(() => {
        setLoader(false);
      });
    }
  }

  function editGroup() {
    if (group != null) {
      let itemModelo: Group = group;
      itemModelo.name = nameChanged;
      EditGroup(itemModelo).then(() => {
        setMessageOk(true);
      })
      .catch((error) =>{
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setErrorMessage(error.response.data);
        }
        setMessageError(true);
      })
      .finally(() => {
        setLoader(false);
      });
    }
  }
  function resetMessage() {
    setMessageOk(false);
    setMessageError(false);
    setErrorMessage(t("ErroAoSalvar.message"));
  }
  
  const onChangeSetName = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue != undefined) {
        setNameChanged(newValue);
      }
      else
      {
        setNameChanged("");
      }
    },
    []
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Excluir",
    closeButtonAriaLabel: "Excluir",
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key ===  'Enter'){
      crudGroup();
    }
  }  
  
  return (
    <div>
      <TextFieldComp
        label={t("Nome.group")}
        value={nameChanged}
        onChange={onChangeSetName}
        autofocus
        onKeyDown={onKeyDown}
      />
      {loader && (
        <LoaderComp/>
      )}
      {isMessageError ? (
        <Message text={errorMessage} type={MessageBarType.error} />
      ) : (
        <div></div>
      )}
      {isMessageOk ? (
        <Message text={t("GrupoSalvo.message")} type={MessageBarType.success} />
      ) : (
        <div></div>
      )}
      <Btn text={t("Salvar.message")} onClick={crudGroup} />
    </div>
  );
};

export default GroupsConfig;
