import { Stack } from "@fluentui/react/lib/Stack";
import { ConfigProvider, DatePicker as DatePickerAnt } from 'antd';
import { Locale } from 'antd/es/locale-provider';
import localeUs from 'antd/es/locale/en_US';
import localeBr from 'antd/es/locale/pt_BR';
import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import LoaderComp from '../../components/Loader/LoaderComp';
import stylesList from "../Style/List.module.css";
import GridTitle from './GridArchive/GridTitle';
import { RangePickerIcon } from '../../assets/icons/icons.tsx/RangePickerIcon';
import { SelectedRangePickerIcon } from '../../assets/icons/icons.tsx/SelectedRangePickerIcon';
import { HoverRangePickerIcon} from '../../assets/icons/icons.tsx/HoverRangePickerIcon';
import GridArchive from './GridArchive/GridArchive';
import { GetAllLogErrors } from "../../services/LogErrorsServices";
import { LogError } from "../../model/LogError";
import { Alert, InputAdornment, Snackbar, SnackbarCloseReason, TextField } from "@mui/material";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../context/ProfileProvider/useProfile";

const LogErrorsList: React.FC<any> = () => {
  const navigate = useNavigate()
  const profile = useProfile();

  const [limit, setLimit] = useState<number>(50);
  let [skip, setSkip] = useState<number>(0);
  const [logItens, setlogItens] = useState<LogError[]>([]);
  let [logMax, setlogMax] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [selectDateBegin, setSelectDateBegin] = useState<Date | undefined | null>(undefined);
	const [selectDateEnd, setSelectDateEnd] = useState<Date | undefined | null>();
	const [localization, setLocalization] = useState<Locale>();
  const [reload, setReload] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("")
  const { t, i18n } = useTranslation();
	const onSelectDateBegin = (date: Date | null | undefined) => {
		setSelectDateBegin(date);
		setReload(true);
	};

	const onSelectDateEnd = (date: Date | null | undefined) => {
		setSelectDateEnd(date);
		setReload(true);
	};

  const [isHoverRangePicker, setIsHoverRangePicker] = useState<boolean>(false)
  const [iconStyleRangePicker, setIconStyleRangePicker] = useState<boolean>(false)
  const [nameSearch, setNameSearch] = useState<string>("")
  const [isTyping, setIsTyping] = useState<boolean>(false)

  const { RangePicker } = DatePickerAnt;

	useEffect(() => {
		if (i18n.language === 'pt-BR') {
			setLocalization(localeBr);
		} else {
			setLocalization(localeUs);
		}
	}, [i18n.language]);

  function home(){
    return navigate("/")
  }

    useEffect(() => {
      setIsLoaded(false)
      GetAllLogErrors(skip, limit, nameSearch, selectDateBegin?.toDateString(), selectDateEnd?.toDateString())
      .then((log) => {
        if (log.length < limit) {
        setlogMax(false);
        }             
        setlogItens([...logItens, ...log]);
        setIsLoaded(true)
      })
      .catch((e)=>{
        setErrorMessage(e.response.data)
        setOpenSnackBar(true)
      })
      .finally(() => {
        setReload(false)
      });
    }, [skip]);

    useEffect(() => {
      if(profile.adminSystem){
        getAllLogErrors()
      }else{
        home()
      }
    }, [selectDateBegin, selectDateEnd]);

    function getAllLogErrors(){
      setIsLoaded(false)
      GetAllLogErrors(skip, limit, nameSearch, selectDateBegin?.toDateString(), selectDateEnd?.toDateString())
      .then((log) => {
        if (log.length < limit) {
        setlogMax(false);
        }else{
          setlogMax(true);
        }
        setSkip(0)
           
        setlogItens(log);
        setIsLoaded(true)
      })
      .catch((e)=>{
        console.log(e)
        setErrorMessage(e.response.data)
        setOpenSnackBar(true)
      })
      .finally(() => {
        setReload(false)
      });
    }
    
    useEffect(() => {
      if (isTyping) {
        const timer = setTimeout(() => {
          if(profile.adminSystem){
            getAllLogErrors()
          }else{
            home()
          }
          setIsTyping(false)
        }, 2000);
        
        return () => clearTimeout(timer);
      }
    }, [nameSearch]);

    function onchangeSeachName( event: React.ChangeEvent<HTMLInputElement>){
      setNameSearch(event.target.value)
      setIsTyping(true)
    }

    function useVisibility(
      cb: (isVisible: boolean) => void,
      deps: React.DependencyList
    ): (node: any) => void {
      const intersectionObserver = useRef<IntersectionObserver | null>(null);
      return useCallback((node) => {
        if (intersectionObserver.current) {
          intersectionObserver.current.disconnect();
        }
  
        intersectionObserver.current = new IntersectionObserver(([entry]) => {
          cb(entry.isIntersecting);
        });
  
        if (node) intersectionObserver.current.observe(node);
      }, deps);
    }

    const lastBook = useVisibility(
      (visible) => {
        if (visible) {
          setSkip(logItens.length + limit);
        }
      },[skip, logItens]
    );

    const onRenderLabel = (label: string) => {
      return (
        <div style={{ 
          display: 'flex',
          fontSize: "18px",
          fontWeight: 600,
          padding: "0px 0px 10px 0px",
          color: "rgb(45, 45, 45, 0.8)",
         }}>
          {label}
        </div>
      );
    };

    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: SnackbarCloseReason,
    ) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSnackBar(false);
    };

    return(
      <>
        <div style={{height:"100%"}}>
          <div style={{height:"100%", display: "flex", flexDirection: "column"}}>
            <Stack  className={stylesList.hPage}>  
              <Stack.Item>
              <h2>{"LogErrors"}</h2> 
              </Stack.Item>

              <div style={{borderBottom:"1px solid rgb(0, 0, 0, 0.08)", margin:"34.5px 0px 25.5px 0px",}}></div>

              <div style={{display:"flex", alignItems:"end"}}>
                <Stack styles={{root:{display:"block"}}}>
                  <Stack.Item styles={{root:{display:"flex", alignItems:"end"}}}>
                    <ConfigProvider locale={localization}>
                      <div>{onRenderLabel("Data de Criação")}
                        <RangePicker
                          style={{ height: '44px', width:"280px"}}
                          disabled={reload}
                          placeholder={['dd/mm/aaaa', ' dd/mm/aaaa']}
                          onChange={(values) => {
                            onSelectDateBegin(values?.[0]?.toDate());
                            onSelectDateEnd(values?.[1]?.toDate());
                          }}
                          separator={<div> - </div>}
                          format={i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                          suffixIcon={!iconStyleRangePicker? (!isHoverRangePicker? <RangePickerIcon/> : <HoverRangePickerIcon/>) : <SelectedRangePickerIcon/>}
                          onMouseEnter={() => setIsHoverRangePicker(true)}
                          onMouseLeave={() => setIsHoverRangePicker(false)}
                          onOpenChange={(open) => {
                            if (open) {
                              setIconStyleRangePicker(true)
                            } else {
                              setIconStyleRangePicker(false)
                            }
                          }}
                        />
                      </div>
                    </ConfigProvider>     
                  </Stack.Item>  
                </Stack>
                
                <TextField
                  placeholder={t("LogErrors.search")}
                  value={nameSearch}
                  onChange={onchangeSeachName}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon/>
                      </InputAdornment>
                    ),
                    sx: {
                      fontSize: "18px",
                      marginLeft: "25px",
                      '&::placeholder': {
                        color: 'rgba(45, 45, 45, 0.3)',
                      },
                      height:"44px",
                      font: "normal normal normal 16px/24px Segoe UI !important",
                    },
                  }}
                  sx={{
                    marginLeft: "20px",
                    borderRadius: "6px",
                    borderColor: "#2D2D2D33",
                    justifyContent: "center",
                    width: "300px",
                    height: "44px",
                    '& .MuiOutlinedInput-root': {
                      borderRadius: "6px",
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2D2D2D33',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#005A9E80 !important',
                    },
                  }}
                />
              </div>
            </Stack>
            
            <GridTitle/>
            { logItens.length != 0 &&
              <div style={{display:"flex", flexDirection:"column",height:"100%"}}>
                
                {logItens.map((iten, index) => (
                  <GridArchive              
                    ref={(logItens[logItens.length - 1].id === iten.id && logMax && isLoaded) ? lastBook : null}
                    logError={iten}
                    lastLine={index == logItens.length - 1}
                    firstLine={index == 0}
                  />
                ))}
                
              </div>
            }

            {reload &&(<div style={{ height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <LoaderComp big={true}/>
              </div>)
            }

            {reload == false && logItens.length == 0 && 
              <div className={stylesList.noRegistry} style={{backgroundColor: logItens.length == 0 ? "#F5F5F5" : ""}}></div>      
            }        
          </div>
        </div>
        <Snackbar open={openSnackBar} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            icon={false}
            sx={{ 
              width: '100%',
              background:"#E8473F",
              color:"#FFFF"
            }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </>
    );
}

export default LogErrorsList;
