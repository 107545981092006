import React, { useEffect, useState, useRef, useCallback  } from "react";
import stylesList from "../../Style/List.module.css";
import { useTranslation } from "react-i18next";
import { UserPermission } from "../../../model/UserModel";
import { 
  GetWorkUser, GetWorkUserName
 } from "../../../services/WorkSpaceServices";
 import UserPermissionItem from "./UserPermissionItem";

interface WorkSpacePermissionsListConfig {
    nameOrg: string;
    idWorkspace: string;
    name: string;
  }

  function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
  
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });
  
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }

const WorkSpacePermissionsList: React.FC<WorkSpacePermissionsListConfig> = ({nameOrg,idWorkspace, name}) => {
    const { t, i18n } = useTranslation();
    const [userPermission, setUserPermission] = useState<UserPermission[]>([]);
    let [GroupMax, setGroupMax] = useState<boolean>(true);
    let [skip, setSkip] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);

    const lastGroup = useVisibility(
      (visible) => {
        if (visible) {
          if(name == "")
          {
            GetWorkUser(idWorkspace,skip, limit).then((newGroups) => {
              if (newGroups.length < limit) {
                setGroupMax(false);
              }
              setSkip(skip + newGroups.length);
              setUserPermission([...userPermission, ...newGroups]);
            });
          }
          else
          {
            GetWorkUserName(idWorkspace, skip, limit, name).then((newGroups) => {
              if (newGroups.length < limit) {
                setGroupMax(false);
              }
              setSkip(skip + newGroups.length);
              setUserPermission([...userPermission, ...newGroups]);
            });
          }
        }
      },
      [skip, idWorkspace, userPermission]
    );

    useEffect(() => {
      if(name == "")
      {
        GetWorkUser(idWorkspace,0, limit).then((newWork) => {
          if (newWork.length < limit) {
            setGroupMax(false);
          }
          setSkip(newWork.length);
          setUserPermission(newWork);
        });
      }
      else
      {
        GetWorkUserName(idWorkspace, 0, limit, name).then((newWork) => {
          if (newWork.length < limit) {
            setGroupMax(false);
          }
          setSkip(newWork.length);
          setUserPermission(newWork);
        });
      }
    }, [idWorkspace,name]);

    return (
            <ul className={stylesList.ulContent}>
                <li className={`${stylesList.itemContent} ${stylesList.title}`}>
                    <>{t("Nome.username")}</>{" "}
                    </li>
                      {userPermission.map((userPerm) => (
                            <UserPermissionItem
                            key={userPerm.userId}
                            idUser={userPerm.userId}
                            nameUser={userPerm.userName}
                            typeUser={userPerm.permissionType}
                            isAdminOrganization={userPerm.isAdminOrganization}
                            isReaderOrganization={userPerm.isReaderOrganization}
                            idWorkspace={idWorkspace}
                            ref={userPermission[userPermission.length - 1].userId === userPerm.userId && GroupMax ? lastGroup : null}
                            />
                ))}
                </ul>
    )
}

export default WorkSpacePermissionsList;