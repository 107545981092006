import React, { MouseEvent, useState } from "react";
import { AlertColor, Box, Button, Card, createTheme,  ThemeProvider, Tooltip, Typography } from "@mui/material";
import StarIcon, { StarSlashedIcon } from "../../../assets/svg/StarIcon";
import { Favorite, QuickAccessWorkspace } from "../../../model/UserModel";
import { WorkspaceIcon } from "../../../assets/svg/WorkspaceIcon";
import { DeleteFavorites, SetFavorites } from "../../../services/UserServices";
import WorkSpaceItemListFileComponentImage from "../../../pages/WorkSpaceItem/WorkSpaceItemListFileComponentImage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface IMiniCard {
  id: string;
  type: number;
  favoriteName: string;
  extension: string | undefined;
  isFavorited: boolean;
  favorites: Favorite[];
  setFavorites: (set: Favorite[]) => void;
  informationCardType: "quickAccess" | "organizations"
  setPopupMessage: (set: string | undefined) => void;
  setSeverity: (set: AlertColor) => void;
  nameOrg: string;
  setQuickAccessWorkspaces: (set: QuickAccessWorkspace[]) => void;
  quickWorkspaces: QuickAccessWorkspace[];
}

export const MiniCard: React.FunctionComponent<IMiniCard> = ({
  id,
  type,
  favoriteName,
  extension,
  isFavorited,
  favorites,
  setFavorites,
  setPopupMessage,
  setSeverity,
  nameOrg,
  setQuickAccessWorkspaces,
  informationCardType,
  quickWorkspaces
}) => {

  const [isStarSlashedNeeded, setIsStarSlashedNeeded] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#22638D',
            color: 'white',
            fontSize: '14px',
            height: "30px",
            textAlign: "left",
            font:" normal normal normal 12px/18px Segoe UI",
            letterSpacing: "0.11px",
            opacity: 1
          },
          arrow: {
            color: '#22638D',
          },
        },
      },
    },
  });

  const handleMiniCardInformations = (type: number): { icon: React.ReactNode, name: string } => {
    switch(type) {
      case 1:
        return {
          icon: <WorkspaceIcon 
            height="14"
            width="14"
            color=""
          />,
          name: "Workspace"
        }

      case 2:
        return {
          icon: <WorkSpaceItemListFileComponentImage 
            extension={extension}
            boxLayout={false}
            isFolder={false}
          />,
          name: t("Minicard.File")
        }

        case 3:
          return {
            icon: <WorkSpaceItemListFileComponentImage 
              extension={extension}
              widthIcon="14"
              boxLayout={false}
              isFolder={true}
            />,
            name: t("Minicard.Folder")
          }
      
      default: 
        return {
          icon: <></>,
          name: ""
        }
    }
  }

  const RedirectToWorkspace = () => {
    /* 
      verifica se o card em questão é do tipo workspace.
      Se sim, ele permite o envio do usuário para o workspace, caso esse card seja clicado.
    */
   if (type == 1) {
      navigate(`/${nameOrg}/wsallowed/${id}`)
   }
  }

  function favoriteItemHandles(event: MouseEvent<HTMLDivElement>) 
  {
    event.stopPropagation();
    if (isFavorited == true) {
      DeleteFavorites(id, nameOrg)
      .then((data) => {
        setFavorites(data.favorites);
        setQuickAccessWorkspaces(data.workspacesVOs);
        setPopupMessage(t("LoggedHome.favoriteExclude"))
        setSeverity("success")
      })
    } else {
      let favoriteObject: Favorite = {
        extension: extension,
        favoriteName: favoriteName,
        type: 1,
        isFavorited: true,
        id: id!,
        organizationInternalName: nameOrg!
      }

      SetFavorites(favoriteObject, nameOrg!)
      .then((data) => {
        setFavorites(data.favorites);
        setQuickAccessWorkspaces(data.workspacesVOs);
        setPopupMessage(`${ handleMiniCardInformations(type).name } ${t("LoggedHome.favoriteSuccess")}`)
        setSeverity("success")
      })
      .catch(() => {
        setPopupMessage(t("LoggedHome.favoriteError"));
        setSeverity("error")
      })
    }
  }

  function handleMiniCardWidth() {
    if (informationCardType == "quickAccess") {
      return {
        lg: favorites?.length == 1 ? "460px" : "242px",
        xl: favorites?.length == 1 ? "605px" : "242px"
      }
    } 

    return {
      lg: quickWorkspaces?.length == 1 ? "480px" : "242px",
      xl: quickWorkspaces?.length == 1 ? "605px" : "242px"
    }
  }


  return (
    <Box
      onClick={e => {
        e.stopPropagation()
        RedirectToWorkspace()
      }}
      sx={{
        cursor: "pointer"
      }}
    >
      <Card
        elevation={0}
        sx={{
          width: handleMiniCardWidth(),
          border: "1px solid #0000001A",
          borderRadius: "3px",
          position: "relative",
          mr: "10px",
          display: "flex",
          alignItems: "center",
          padding: "11px",
        }}
      >
        <Box
          width="38px"
          height="38px"
          sx={{
            border: "1px solid #0000001A",
            borderRadius: "3px",
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr="8px"
        >
          { handleMiniCardInformations(type).icon }
        </Box>
        <Box display="flex" justifyContent="space-between" width="75%">
          <Box>
            <Typography
              sx={{
                textAlign: "left",
                font: "normal normal normal 12px/18px Segoe UI",
                letterSpacing: "0px",
                color: "#2D2D2D",
                opacity: 0.5,
              }}
            >
              { handleMiniCardInformations(type).name }
            </Typography>
            <Typography
              width="150px"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              sx={{
                textAlign: "left",
                font: "normal normal 600 14px/21px Segoe UI",
                letterSpacing: "0px",
                color: "#2D2D2D",
                opacity: 1,
              }}
            >
              { favoriteName }
            </Typography>
          </Box>
          <Box sx={{
            top: 0,
            position: "absolute",
            right: 0,
            marginRight: "10px",
            marginTop: "5px",
          }} onClick={(event) => {favoriteItemHandles(event)}}
            onMouseEnter={() => {
              if (isFavorited) {
                setIsStarSlashedNeeded(true);
              }
            }}

            onMouseLeave={() => {
              setIsStarSlashedNeeded(false);
            }}
          >
            { isStarSlashedNeeded ? (
              <ThemeProvider theme={theme}>
                <Tooltip title={t("Minicard.Desfav")} arrow>
                  <Box>
                    <StarSlashedIcon width="14" height="14" opacity={1} />
                  </Box>
                </Tooltip>
              </ThemeProvider>
            ) : (
              <StarIcon width="14" height="14" opacity={isFavorited ? 1 : 0.2}/> 
            ) }
          </Box>
        </Box>
      </Card>
    </Box>
  );
};



