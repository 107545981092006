import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import { Favorite } from "../../../model/UserModel";
import { StarSlashedIcon } from "../../../assets/svg/StarIcon";
import { useTranslation } from "react-i18next";

export const MiniCardNothingToShow = () => {

    const { t } = useTranslation();

    return (
        <Card
            elevation={0}
            sx={{
                width: {
                    lg: "535px",
                    xl: "605px"
                },
                border: "1px solid #0000001A",
                borderRadius: "3px",
                position: "relative",
                mr: "10px",
                display: "flex",
                alignItems: "center",
                padding: "18px",
                height: "62px"
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    marginRight="18px"
                >
                    <StarSlashedIcon width="24" height="24" opacity={.6} />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Box marginBottom="4px">
                        <Typography
                            textAlign="left"
                            fontSize="14px"
                            fontWeight="600"
                            sx={{
                                opacity: 1
                            }}
                        >
                            {t("Minicard.NothingViewMiniCardTitle")}
                        </Typography>
                    </Box>
                    <Box width="100%">
                        <Typography
                            textAlign="left"
                            fontSize="14px"
                            sx={{
                                opacity: .6
                            }}
                        >
                            {t("Minicard.NothingViewMiniCardContent")}                     
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}