
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useState } from "react";
import { InformationCardComponent } from "./components/InformationCardComponent/InformationCardComponent";
import { OrganizationInfoWrapper } from "./components/OrgInfoTrackerListing/components/OrganizationInfoWrapper";
import { OrgInfoTrackerListing } from "./components/OrgInfoTrackerListing/OrgInfoTrackerListing";
import { OrgInfoActivitiesWrapper } from "./components/OrgInfoTrackerListing/components/OrgInfoActivitiesWrapper";
import { OrgInfoActivitiesHeader } from "./components/OrgInfoTrackerListing/components/OrgInfoActivitiesHeader";


const OrganizationInfo = () => {
  const [popupMessage, setPopupMessage] = useState<string | undefined>(undefined);
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [userError, setUserError] = useState<boolean>(false);

  const handleClose = () => {
    setPopupMessage(undefined);
  }

  return (
    <>
      <OrganizationInfoWrapper
        userError={userError}
      >
          <InformationCardComponent 
            setPopupMessage={setPopupMessage}
            setSeverity={setSeverity}
            setUserError={setUserError}
          />
          <OrgInfoActivitiesWrapper>
            <OrgInfoActivitiesHeader />
            <OrgInfoTrackerListing 
              setPopupMessage={setPopupMessage}
              setSeverity={setSeverity}
              setUserError={setUserError}
            />
          </OrgInfoActivitiesWrapper>
      </OrganizationInfoWrapper>
      <Snackbar
          open={popupMessage != undefined}
          autoHideDuration={6000}
          message="Archived"
          onClose={handleClose}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          sx={{ bottom: { xs: 50, sm: 20 } }}
        >
          <Alert elevation={6} variant="filled" sx={{ width: '100%' }} severity={severity} onClose={handleClose}>
            { popupMessage }
          </Alert>
      </Snackbar>
    </>
  );
};


export default OrganizationInfo;
