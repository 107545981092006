import { AlertColor, Grid } from "@mui/material";
import { IContextualMenuItem } from "@fluentui/react";
import DocumentDetailMetaDataWrapper from "./components/DocumentDetailMetaData/DocumentDetailMetaDataWrapper/DocumentDetailMetaDataWrapper"; 
import DocumentDetailMetaDataInformation from "./components/DocumentDetailMetaData/DocumentDetailMetaDataInformation/DocumentDetailMetaDataInformation";
import { useTranslation } from "react-i18next";
import { WorkSpaceUserName, ItemValidationRule } from "../../../../../model/ItemModel";
import { MenuConfig } from "../../../../../components/Menu/MenuConfig";
import modifyDate from '../../../../../assets/icons/data-de-modificacao.svg';
import users from '../../../../../assets/icons/usuarios_alter.svg';
import moment from 'moment';
import DocumentDetailBasicWrapper from "./components/DocumentDetailBasicData/DocumentDetailBasicWrapper";
import DocumentDetailInternalData from "./components/DocumentDetailBasicData/components/DocumentDetailInternalData/DocumentDetailInternalData";
import DocumentDetailinternalSeparator from "./components/DocumentDetailBasicData/components/DocumentDetailinternalSeparator/DocumentDetailinternalSeparator";
import DocumentDetailInternalDate from "./components/DocumentDetailBasicData/components/DocumentDetailInternalDate/DocumentDetailInternalDate";
import { TagName } from "../../../../../model/Tags";
import { DescriptionField } from "../../../../WorkSpaceItem/WorkSpaceItemListFileCompose/Components/DescriptionField/DescriptionField";
import {  Box } from '@mui/material';
import ValidationRuleButton from "../../../../WorkSpaceItem/WorkSpaceItemListFileCompose/ValidationRuleButton/ValidationRuleButton";
import MenuCont from "../../../../../components/Menu/Menu";
import { description } from "../../../../WorkSpaceItem/WorkSpaceItemListStyle/ColumnStandard";

interface IDocumentDetailHeader 
{
    setValidationRule: (itemValidationRule: ItemValidationRule | undefined, index: number, isUpload: boolean) => void;
    workItem: WorkSpaceUserName;
    closeDetail: () => void;
    returnMenuConfig: () => MenuConfig;
    status: string;
    isSidebarCollapsed: boolean;
    setPopupError: (set: boolean, message: string) => void;
    setPopupOk: (set: boolean, message: string) => void;
    tagInternal: TagName[] | undefined;
    refreshOnlyTags: boolean;
    setRefreshOnlyTags: React.Dispatch<React.SetStateAction<boolean>>;
    isValidateReadViwer: boolean;
    isReleaseConst: boolean;
    itemTypeInternal: string;
    respValidationRule: boolean;
    unvalidType: boolean;
    openPanelTag: () => void;
    isOpenTag: boolean;
    boxLayout: boolean;
    onClickTag: () => void;
    isHover: boolean;
    isValidateViwer: boolean;
    leave: () => void;
    onDragEnterAndOver: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
    progress: number;
    setFolderExternal: (itemId: string, itemName: string | undefined) => void;
    itemType: string;
    timeDeadLineInternal?: string;
    updateTagName: (index:number, tagName: TagName[],  isUpload: boolean) => void;
    indexOfArray: number;
    setTagInternal: (tagName: TagName[] | undefined) => void;
    children: React.ReactNode;
    updateNameDescriptionByIndex:(index:number, name:string, isUpload: boolean, description:string) => void;
    isUpload: boolean;
    nameOrg: string;
    setWorkspace: ((workSpaceUserName: WorkSpaceUserName, index: number, isUpload: boolean) => void);
    idWorkspace:string;
}

export default function DocumentDetailHeader({
    workItem,
    setValidationRule,
    returnMenuConfig,
    status,
    isSidebarCollapsed,
    setPopupError,
    setPopupOk,
    tagInternal,
    isReleaseConst,
    isValidateReadViwer,
    itemTypeInternal,
    respValidationRule,
    unvalidType,
    openPanelTag,
    boxLayout,
    onClickTag,
    isHover,
    isValidateViwer,
    leave,
    onDragEnterAndOver,
    onDragLeave,
    progress,
    setFolderExternal,
    updateTagName,
    indexOfArray,
    setTagInternal,
    children,
    updateNameDescriptionByIndex,
    isUpload,
    nameOrg,
    setWorkspace,
    idWorkspace
}: IDocumentDetailHeader)
{
    const { t } = useTranslation();

    return (
        <Grid container flexDirection="column" sx={{ background: "white" }}>
            <DocumentDetailMetaDataWrapper>
                <Grid item>
                    <Grid container justifyContent="space-between">
                        <DocumentDetailMetaDataInformation 
                            workItem={workItem}
                            setPopupError={setPopupError}
                            setPopupOk={setPopupOk}
                            tagInternal={tagInternal}
                            openPanelTag={openPanelTag}
                            boxLayout={boxLayout}
                            itemType={itemTypeInternal}
                            onClickTag={onClickTag}
                            isSidebarCollapsed={isSidebarCollapsed}
                            isHover={isHover}
                            isValidateReadViwer={isValidateReadViwer}
                            isValidateViwer={isValidateViwer}
                            leave={leave}
                            onDragEnterAndOver={onDragEnterAndOver}
                            onDragLeave={onDragLeave}
                            progress={progress}
                            setFolderExternal={setFolderExternal}
                            unvalidType={unvalidType}
                            updateTagName={updateTagName}
                            indexOfArray={indexOfArray}
                            setTagInternal={setTagInternal}
                            updateNameDescriptionByIndex={updateNameDescriptionByIndex}
                            isUpload={isUpload}
                        />

                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                                height: '100%'
                            }}>
                                <ValidationRuleButton
                                    isValidateReadViwer={isValidateReadViwer}
                                    respValidationRule={respValidationRule}
                                    unvalidType={unvalidType}
                                    indexOfArray={indexOfArray}
                                    nameOrg={nameOrg}
                                    workspaceItem={workItem}
                                    setPopupError={setPopupError}
                                    setWorkspace={setWorkspace}
                                    setPopupOk={setPopupOk}
                                    idWorkspace={idWorkspace}
                                    setValidationRule={setValidationRule}
                                    isUpload={isUpload}
                                />
                                <MenuCont 
                                    menuConfig={returnMenuConfig()}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{
                    paddingY: '10px',
                    width: '100%',
                    maxWidth: "600px",
                    marginTop: '19px'
                }}>
                    <DescriptionField 
                        indexOfArray={indexOfArray}
                        id={workItem.id}
                        name={workItem.name}
                        descriptionFile={workItem.description}
                        itemType={workItem.itemType}
                        layoutPendencies={false} 
                        setPopupError={setPopupError}
                        setPopupOk={setPopupOk}
                        isDetailPage={true}
                        updateNameDescriptionByIndex={updateNameDescriptionByIndex}
                        isUpload={isUpload}
                        boxLayout={boxLayout}
                    />
                </Grid>
                <Grid item>
                    <DocumentDetailBasicWrapper
                        isSidebarCollapsed={isSidebarCollapsed}
                    >
                            <DocumentDetailInternalData 
                                icon={modifyDate}
                                textInformation={t("DetailDocumentPage.ModDate")}
                                valueInformation={moment(workItem?.lastWriteDate).format("DD/MM/YYYY")}
                                isSidebarCollapsed={isSidebarCollapsed}
                            />
                            <DocumentDetailinternalSeparator 
                                separator="|"
                            />
                            <DocumentDetailInternalData  
                                textInformation={t("DetailDocumentPage.ModResp")}
                                valueInformation={ workItem?.username! }
                                icon={users}
                                isSidebarCollapsed={isSidebarCollapsed}
                            />
                            <DocumentDetailinternalSeparator 
                                separator="|"
                            />
                            <DocumentDetailInternalData  
                                textInformation="Status"
                                valueInformation={ status }
                                hasCircleDetail      
                            />

                            <DocumentDetailinternalSeparator 
                                separator="|"
                            />
                            <DocumentDetailInternalDate  
                                textInformation={t("DetailDocumentPage.Term")}
                                isReleaseConst={isReleaseConst}
                                timeDeadLine={workItem.validationRule?.timeDeadline!}
                                validationRule={workItem.validationRule!}
                                actionForwadOrBackWait
                            />
                    </DocumentDetailBasicWrapper>
                </Grid>
            </DocumentDetailMetaDataWrapper>
            { children }
        </Grid>
    )
}