import { useTranslation } from "react-i18next"

export const useHeaderActions = () => {

    const { t } = useTranslation();

    const headerActions = [
        {
            children: t("LoggedHome.FileName"),
            conditionalToHaveEndAlignment: false
        },
        {
            children: t("LoggedHome.Action"),
            conditionalToHaveEndAlignment: false
        },
        {
            children: t("LoggedHome.Responsible"),
            conditionalToHaveEndAlignment: false
        },
        {
            children: t("LoggedHome.LastModification"),
            conditionalToHaveEndAlignment: true
        }
    ]

    
    return {
        headerActions
    }
}