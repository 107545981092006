export default {
    translations: {
      otpDialog: {
        button: "Remove one time password",
        title: "Are you sure that you want to remove your one time password?",
        contentEmail: "Account e-mail:",
        content: "Please be aware that upon confirmation, the multi-factor authentication settings on your account will be removed. They will need to be configured again afterwards.",
        successfullyOtpPass: "One time password accepted successfully.",
        successfullyOtpPassContent: "Your one time password was accepted. Please, click on the confirm button to redirect to login and get allowed to application."
      },
      TwoFa:{
        title:"A code wan sended",
        message:"A code wan sended to your email.",
        button:"Put this code"
      },
      Novo: {
        message: 'New'
      },
      Nome: {
        message: 'Document name',
        text: 'Name',
        username: 'Username',
        workspace: 'Workspace name',
        group: 'Group name',
        validation: 'Validation Name',
        organization: 'Organization Name'
      },
      D4Sign:{
        Confi: 'D4 Sign Configuration',
        SafeBox: 'Safe Box',
        Error: 'D4Sign return error',
        Download: 'Download signed document',
        Wait: 'Wait, we prepare the link'
      },
      Sensibility:{
        word:"Sensibility",
        higth:"Higth",
        medium:"Medium",
        low:"Low"
      },
      Extencao: {
        message: 'Extension'
      },
      Editar: {
        message: 'Edit'
      },
      Excluir: {
        message: 'Remove'
      },
      Clone: {
        message: 'Clone'
      },
      Iniciais: {
        message: 'Initials'
      },
      NomeInterno: {
        message: 'Internal name'
      },
      UsuariosAdm: {
        message: 'Admin users'
      },
      ConfigWork: {
        message: 'Workspace Settings'
      },
      Salvar:{
        message: 'Save'
      },
      Deletar: {
        message: "Delete"
      },
      Resetar: {
        message: "Reset"
      },
      
      SalvarWork: {
        message: 'Save Worskpace'
      },
      CloneWork: {
        message: 'Clone Worskpace'
      },
      Cancelar: {
        message: 'Cancel'
      },
      Fechar: {
        message: 'Close'
      },
      WorkSalvo: {
        message: 'Workspace saved'
      },
      ErroAoSalvar:{
        message: 'Could not save'
      },
      CanNotClone:{
        message: 'Could not clone'
      },
      UsuariosSugeridos: {
        message: 'suggested users'
      },
      UsuariosNaoEncontradors: {
        message: 'Users not found'
      },
      SalvoOK: {
        message: 'Save Ok'
      },
      AdmOrg: {
        message: 'Organization administrator'
      },
      OnlyRead: {
        message: 'Only read'
      },
      MinDeCaracteres:{
        message: 'The name must contain 3 or more caracters'
      },
      MaxDeCaracteres:{
        message:'The name must be a maximum of 50 characters'
      },
      SalvarUsuario:{
        message:'Save users'
      },
      ExluirUsuarioValid:{
        message:'Are you sure you want to delete the user'
      },
      UserExcluido:{
        message:'User deleted.',
        error:'Error: User cant deleted.'
      },
      Continuar:{
        message:'Continue'
      },
      Usuarios:{
        message:'Users'
      },
      Org:{
        saveOk:'Organization saved'
      },
      SalvarOrg:{
        message:'Save Organization'
      },
      NomeOrg:{
        message:'Organization name'
      },
      NomeWorkspace:{
        message:'Workspace name'
      },
      ExcluidoComSucesso:{
        message:'Successfully deleted'
      },
      EditadoComSucesso:{
        message:'Successfully edited'
      },
      ConfigOrg:{
        message:'Organization Settings'
      },
      ExcluirOrg:{
        message:'Delete organization'
      },
      CertezaExcluir:{
        message:'Are you sure you want to delete?'
      },
      CertezaExcluirOrganization:{
        message:'Are you sure you want to delete this Organization?'
      },
      Workspace:{
        permission:"Workspace permissions",
        reader:"Reader",
        writer:"Writer",
        none:"None",
        deleteSucess:"Deleted Workspace",
        createSucess:"Created Workspace",
        cloneSucess:"Cloned Workspace",
      },
      Item:{
        permission:"Item permissions",
        reader:"Reader",
        writer:"Writer",
        none:"None",
        deleteSucess:"Deleted Item",
        deleteProblem:"Item problem on delete",
        createSucess:"Created Item",
        folow:"Folow father",
        isFolow:"Folow",
        dontFolow:"Don't folow",
        deleteBtn:"Delete ",
        editBtn:"Rename Item",
        deleteMessage:"Are you sure you want to delete?",
        setDraft:"Draft",
        setRelease:"Release",
        d4SignVersion:"Get signed version",
        undoDraft:"Undo Draft",
      },
      CertezaExcluirWorkspace:{
        message:'Are you sure you want to delete this Workspace?'
      },
      EscrevaNomeOrg:{
        message:'Enter the organization name'
      },
      NomeIncorreto:{
        message:'Wrong name'
      },
      Organizacoes:{
        message:'Organizations'
      },
      GrupoSalvo:{
        message:'Saved group'
      },
      AlterarUsuarios:{
        message:'Change users'
      },
      SalvarGrupo:{
        message:'Save Group'
      },
      GrupoExcluido:{
        message:'Excluded group.'
      },
      CertezaExcluirGrupo:{
        message:'Are you sure you want to Delete the group?'
      },
      Adicionar:{
        message:'Add'
      },
      AdicionarUsuario:{
        message:'Add user'
      },
      UsuarioDoGrupo:{
        message:'Group users'
      },
      Grupos:{
        message:'Groups'
      },
      TiposDeDocumento:{
        message:'Document Types'
      },
      ValidationRule:{
        menu:'Validation Rules',
        errorFind:'Validation Rule doesn\'t found',
        saveOk:'Validation Rule saved',
        error:'Error on save',
        addActivity:"Add activity in workflow",
        backOperationLabel:"Back action Label",
        forwardOperationLabel:"Forward action Label",
        dinamic:"Dynamic Responsible",
        erroName:"Name must isn't empty",
        erroBackOperationLabel:"Go Back Label must isn't empty",
        erroForwardOperationLabel:"Forward Label must isn't empty",
        erroItensTagUser:"Must have at least one responsible",
        doYouWantToRemoveTheValidationRuleUsedOnTheseFiles:"Are you sure you want to remove this Validation Rule? It will no longer be possible to add documents to it. The documents that are already in this flow will follow until they are released",
        erroTermTypes:"Error in find term types",
      },
      Empity:{
        message:'Empty'
      },
      Arvore:{
        message:'Tree'
      },
      Configuracoes:{
        message:'Settings'
      },
      Expandir:{
        message:'Expand'
      },
      Diminuir:{
        message:'Decrease'
      },
      Sair:{
        message:'Logout'
      },
      AlteradoComSucesso:{
        message:'Successfully changed'
      },
      AssinadoComSucesso: {
        message: "signed successfully"
      },
      FinalizadoComSucesso: {
        "message": "Document concluded."
      },
      AlgoErradoAconteceu: {
        message:'Something went wrong'
      },
      SenhasNaoBatem:{
        message:'passwords dont match'
      },
      CodigoNaoEncontrado:{
        message:'Code not found.'
      },
      SemCodigo:{
        message:'No code'
      },
      Senha:{
        message:'Password',
        newPassword: 'New password',
        typeYourPassword: "Enter your password...",
        typeYourPasswordAgain: "Enter your password again...",
        newPasswordRequirements: "The password must have at least 6 characters, with an uppercase letter, a special character and a number."
      },
      ConfirmarSenha:{
        message:'Confirm new password'
      },
      Enviar:{
        message:'Send'
      },
      Confirmar:{
        message:'Confirm'
      },
      IrParaLogin:{
        message:'Go to Login',
        message2: 'Go to Login'
      },
      Entrar:{
        message:'Get in',
        forgotPassword:'Forgot password',
        codPage:"Go to e-mail code page",
        characterMinimun: "Minimum characters",
        specialcharacters: "Special character",
        oneNumber: "One number",
        capitalLetter: "capital letter"
      },
      InsiraEmail:{
        message:'Enter your registered email'
      },
      InsiraSenha:{
        message:'Enter your registered password'
      },
      Code:{
        message:'Code',
        acepted:'Code accepted',
        header:'E-mail code'
      },
      AccessCode: {
        message: 'Access by code',
        label: 'Access Code',
        info: 'Have you received an access code to access the platform? Enter this code in the field below.'
      },
      LembrarDeMim:{
        message:'Remember me'
      },
      EsqueceuSenha:{
        message:'Forgot your password?'
      },
      UsuarioOuSenhaIncorretors:{
        message:'Incorrect username or password'
      },
      UsuarioOuCodeIncorretors:{
        message:'Incorrect username or code'
      },
      DoitLogin:{
        message:'Do it Login',
        sucess:"Accepted code"
      },
      Upload:{
        done:'Finalizado',
        error:'Ocorreu um erro',
        file:"File",
        folder: "Upload Folder",
        newFolder:"Folder",
        permission:"Permission",
        permissionGroup:"Groups Permission",
        permissionUser:"Users Permission"
      },
      NotFound:{
        message: "Not found"
      },
      Datadamodificacao:{
        message:"Created"
      },
      Modificadopor:{
        message:"Modified by"
      },
      Tamanhodoarquivo:{
        message:"Size"
      },
      Folder:{
        save:"Saved with success",
        toSave:"Change"
      },
      DownloadFileError:{
        message:"Error on Download"
      },
      ReleaseError:{
        message:"Error on Set Release"
      },
      DraftError:{
        message:"Erro on Set Draft"
      },
      ArcinvingError:{
        message:"Error on Set Archiving"
      },
      SucessMove:{
        message:"File moved successfully!",
        error:"File donsn't moved!"
      },
      Start:{
        message:"Start"
      },
      Action:{
        message:"Action",
        send:"Send to Validation Rule"
      },
      ErrorMove:{
        message:"Error moving!"
      },
      Revisao:{
        term:"Revision",
        tracker:"Activity Tracker",
        time:"Time",
        ipUser:"Ip",
        name:"User's name",
        message:"Message",
        noEntries:"Sem Entradas"
      },
      DownloadSuccess:{
        message:"Downloaded successfully!"
      },
      DraftDate:{
        message:"Creation date"
      },
      Atualizar:{
        message:"Refresh"
      },
      Description:{
        message:"Description"
      },
      CertezaExcluirDocumentType:{
        message:'Are you sure you want to delete this document type?'
      },
      DocumenType:{
        deleteSucess:"Deleted documet type",
      },
      New:{
        female:"New",
        male:"New"
      },
      ValidationRules:{
        NewActivity:"New Activity",
        Deleted:"Are you sure you want to delete this validation rule?",
        DeletedOk:"Validation Rule deleted",
        RemovedOK:"Validation Rule removed"
      },
      Select:{
        message:"Select",
        error:"Error on find parameters"
      },
      Responsible:{
        message:"Responsible",
        select:"Select Responsible",
        suggestions:"Suggested Responsible",
        notFound:"No responsible found",
        lessOne:"Minimun one responsible"
      },
      ActivityName:{
        message:"Activity Name"
      },
      Pendencias:{
        message:"Pendencies"
      }, 
      CertezaExcluirPermission:{
        message:'Are you sure you want to delete this permission?'
      },
      Label:{
        Back:"GoBack Label",
        Forward:"GoForward Label"
      },
      CPF:{
        choice:'D4 Sign',
        invalid:'Invalid CPF',
        nextStep:'Validate',
        SaveInServer:'Save in server'
      },
      foreign:{
        message:'Foreign'
      },
      loadMore:{
        text:"Load more",
        error:"No more validarion rules found"
      },
      Exibir:{
        message:"View"
      },
      UsuarioSalvo:{
        message:"Saved User"
      }, 
      Copy:{
        message:'Successfully copied!',
        error:'Error copying!'
      },
      Wait:{
        message:"Wait"
      },
      ErroMail:"Invalid e-mail",
      SharedFile:{
        Error:"Error on consult",
        Menu:"Share file",
        FileDontShared:"File don't shared",
        ToShare:"To Share",
        AddEmail:"Add e-mail",
        EndedByDay:"Share until",
        EndedByAcces:"Share until usses acces x times",
        WithoutEnded:"Don't expire",
        ErroOnDelete:"There was an error deleting",
        ErroOnSave:"There was an error saving",
        NotCheckBox:"It is necessary to check a checkbox",
        ListEmails: "List must have emails",
        HasWatermark: "Share with watermark"
      },
      TextField:{
        TooltipTitle: "Click the button to add email."
      },
      permissionDriveOrg:{
        message:"Assign permission to drive",
        permission:"Drive permission"
      },
      renameItem:{
        message:"Rename ",
        error:"Error to rename"
      },
      editItem:{
        message:"Edit "
      },
      owner:{
        message:"Folder owner"
      },
      permissionFolder:{
        message:"Permissions"
      },
      Visualizador:{
        message:"Viewer"
      },
      PermicoesItem:{
        message:"Item permissions",
      },
      Move:{
        moveBy: "Mover to",
        message:"Move ",
        to:" to ",
        toRoot:"Move to root",
        root:" to root",
      },
      AcaoExecutada:{
        message:"Action Execute"
      },
      RevisaoDocument:{
        message:"Document Review"
      },
      Usuario:{
        message:"User"
      },
      DateAction:{
        message:"Action date"
      },
      ErrorDate:{
        Begin:"The start date cannot be greater than the end date.",
        End:"The end date cannot be less than the start date."
      }, 
      Arquivar:{
        message:"Archiving"
      },
      ResetPasswordRequest:{
        message:"Redefine password"
      },
      InvalidEmail:{
        message:"Invalid email"
      },
      EmailSent:{
        message:"Email sent"
      },
      NewResp:{
        message:"Select new responsible",
      },
      ArqResp:{
        message:"Files for which the user is responsible.",
      },
      SelectResp:{
        message:"Select the new user for the validation rule.",
      },
      Organizador:{
        message:"Organizer",
      },
      CalendarLabel:{
        message: "Expiration",
        toolTipMessage:"Expiration defines the validity period of a document.",
        From: "From:",
        To: "To:"
      },
      CalendarButtonToday:{
        TodayButton:"Today",
      },
      ReturnEmptyTracker:{
        MessageSnack:"Tracker not found",
      },
      NameExist:{
        message:"Name already exists"
      },
      Rejeitado:{
        message:"Successfully rejected!"
      },
      StroageInformation:{
        bookcase:"Bookcase",
        shelf:"Shelf",
        position:"Position / Box",
        menu:"Physical Location"
      },
      Delete:{
        message:"Delete",
      },
      SelectValidationRule:{
        message:"Select the Validation rule",
      },
      Remove:{
        message:"Remove"
      },
      NotificationEmail:{
        message:"Receive e-mail notification"
      },
      ActionsInBatch:{
        message:"Actions in batch"
      },
      FilesWentToRelease:{
        message:"Files went to Release"
      },
      ErrorWhenUpdatingToRelease:{
        message:"Error when updating to release"
      },
      FilesWentToDraft:{
        message:"Files went to Draft"
      },
      ErrorWhenUpdatingToDraft:{
        message:"Error when updating to Draft"
      },
      UploadAsRelease:{
        message:"Upload as Release"
      },
      OrganizerCannotDelete:{
        message:"Organizer cannot delete"
      },
      EditorCannotDelete:{
        message:"Editor does not delete"
      },
      "EditorCannotSeeRevision": {
        message:"Editor can't see revision"
      },
      Undo:{
        message:"Undo"
      },
      ConfirmTheDiscardOfTheDraft:{
        message:"This action is irreversible! Confirm the discard of the Draft?"
      },
      Term: {
        message:"Due Date",
        select:"Select Term",
        days:"Days",
        addTerm:"Add deadline type",
        current: "Limite date",
        on: "positive term",
        out: "deadline expired",
        noDeadline: "No deadline"
      },
      FileDownload: {
        message:"File download"
      },
      NoViewerModal: {
        title: "Unable to view this file",
        content: "The file you are trying to preview does not have a preview for reading.To be able to view it, click the button below to download and access the file."
      },
      informationModal:{
        message:"Information to whom the Validation was sent"
      },
      termRule:{
        message:"Choose a deadline for the validation rule.",
        select:"Select Term"
      },
      tag:{
        editMessage:"Successfully edited tag!",
        addMessage:"Tag successfully added!",
        errorEdit:"Error editing tag!",
        errorAdd:"Error adding tag!",
        notFound:"Tags not found",
        new:"New Tag",
        suggested:"Suggested Tags",
        edit:"Edit Tags",
        add:"Add Tags",
        insertMessage:"Insert tags in files and folders for easy access and easily find what you're looking for.",
        LoadingResults:"Loading more results...",
        save:"Save Information",
        cancel:"Cancel",
        nameFile:"File name",
        descFile:"File description",
        semDesc:"Without description",
        fileTag:"File tags",
        tagExist:"The name already exists",
        messageTag:"Tag already exists"
      },
      validationRulePanel: {
        select: "Select the validation rule that matches the current need for the document.",
        execution: "Execution",
        selectUser: "Select the person responsible who will execute the validation rule selected above.",
        alreadySetConfig: "Settings already allocated in the validation rule.",
        alreadySetValidation: 'Validation"s settings already been allocated. Click "Send to responsible" to complete.',
        sendToResponsible: "Send to responsible.",
        OkUnderstand: "Ok, I understand.",
        validationRuleSubmit: "Validation Rule successfully submitted.",
        validationRuleData: "The validation rule has been sent to the person responsible who matches the data listed below.",
        responsible: "Responsible",
        withTerm: "No term.",
        emailWrong: "The item that was pending was completed successfully. You can disregard the received email and close this window.",
        responsableError: "Responsable must be set.",
        norVrFounded: "No validation rule found.",
        title: "Validation rule"
      },
      substituir:{
        message:"Replace"
      },
      DetailDocumentPage: {
        Actions: "Actions",
        Save: "Save",
        Description: "No description...",
        ModDate: "Date modified",
        ModResp: "Document Manager",
        Term: "Term",
        NoTerm: "No Term...",
        Visualization: "Visualization",
        ActivityTracker: "Activity tracker",
        Location: "Physical Location",
        Reviews: "Reviews"
      },
      WhatAreYouLookingFor: {
        message: "What are you looking for..."
      },
      Wizard: {
        organization: "Organization",
        password: "Password",
        confirmPassword: "Confirm your password",
        welcome: "Welcome!",
        return: "Back",
        continue: "Continue",
        conclude: "Conclude Register",
        name: "Name",
        addUsers: "Add",
        errorUsers: "Numbers of users invalid",
        emailInfo:"Add up to 5 employees to be part of the company's work environment",
        descriptionEnd:"Verify if the informations are correct. If is, click in \"",
        descriptionEndBold:"Conclude Register",
        descriptionEndSecond:"\".",
        maxUserlength: "Max users exceeded",
        fillAllFields: "Fill all fields correctly",
        emailAlreadyInList: "E-mail already on list."
      },
      Login: {
        forgotPass: "Forgot my password",
        password: "Password",
        goToEmailPage: "Go to e-mail page",
        accessAcount: "Enter your account",
        helloAgain: "Hello, Again! Fill the text fields with your data to access your account.",
        putYourNewPass: "Put your new password.",
        fillTheFieldsDown: "Fill the fields down with your new password.",
        forgotYourPass: "Forgot your password?",
        fillTheEmailRegistered: "Fill the text field down with your registered e-mail to recover your password.",
        typeYourEmail: "Type your e-mail...",
        typeYourPassword: "Type your password...",
        accessWithCode: "Access with code.",
        doYouReceived: "Do you received the access's code to enter the application? Insert your code in text field down.",
        yourAccessCode: "Access's code.",
        typeYourAccessCode: "Type your access's code...",
        privacyPolicy: "Privacy policy",
        allRights: "All rights reverved.",
        checkYourEmail: "Check your e-mail.",
        confirmationEmail: "If your e-mail is valid, a link was sended to change your password.",
        didntRecieved: "Didn't you recieve your e-mail?",
        clickHereToResend: "Click here to resend",
        alrightUnderstand: "Alright, understand.",
        qrcodeTitle: "QR code generated",
        qrcodeSub: "Scan the qrcode bellow to configure your application.",
        otpTittle: "One time password",
        otpSub: "Put the code that you got in your authenticator.",
        OtpMessage: "One time password",
        qrcodeRedirect: "Afer you scan the qrcode, you must click in the button bellow to insert your one time password.",
        oneUsePassword: "Put a one use password.",
        otpErrorMessage: "One time password must be defined",
        error: "Login problem",
        oneTimePasswordError: "Your one-time password was not accepted, please try again.",
        oneTimePasswordSucess: "Your one-time password was successfully accepted.",
        microsoft: "Continue with",
        confirm: "Confirm"
      },
      InvalidCaptcha:{
        message:"Invalid captcha"
      },
      ResetPasswordSuccess: {
        title: "Password changed successfully",
        subtitle: "Your password has been changed successfully, remember to keep it under lock and key. Click the button below to access login."
      },
      Reset:{
        message:"Reset successfully."
      },
      AutoArquivar:{
        message:"Auto Archive"
      },
      ParaArquivar:{
        message:"To file"
      },
      Temporality:{
        message:"Temporality",
        panelTitle:"Add Temporality",
        dateTemp:"Auto Archive in:",
        select: "Select Temporality",
        descriptionTemporalityMenu: "Select one of the workspaces below to view the temporality report."
      },
      TemporalityComboBox:{
        ExplanatoryText:"Change status to",
        TooltipHostDefalt:"The status defines the state, positioning or situation of the document in the process in which it is found.",
        TooltipHostExpired:'The "Expired" status will define that the deadline has passed to perform some action related to the selected document.',
        TooltipHostArquived:'The "Archived" status will save and inactivate the document. It can be active and resumed again at any time.',
        TooltipHostDraft:'The "Draft" status is defined as draft, that is, the item will be in an initial state and will need to undergo a new validation.',
        PlaceHolder:"Select"
      },
      dataArqchive:{
        message:"Archiving Date"
      },
      WithoutRule: {
        withoutRule: "Without rule.",
        withoutDesc: "No description"
      },
      ViwerInfo: {
        cannotView: "Cannot view this file",
        notPreview: "The file you are trying to view has no preview to read. To be able to view it, click the button below to download and access the file.",
        downloadFile: "Download the file.",
        successfullyDownload: "Successfully download."
      },
      DataTableHeaders: {
        Reviews: "Reviews",
        Actions: "Actions",
        Users: "Users",
        Date: "Due date",
        Name: "Name",
        Description: "Description",
        ExplanatoryText: "Date the action will be executed",
        TooltipHost:"Expiration defines the validity period of a document."
      },
      FilterTables: {
        OrdenationDate: "Order By Date",
        Reviews: "Reviews",
        AlphaOrder: "Alphabetical order",
        OrderByUsers: "Order by users",
        OrderByMessages: "Order by messages",
        Descending: "Descending",
        Ascending: "Ascending",
        apply: "Apply",
        clearFilters: "Clear filters"
      },
      DetailPhLocation: {
        TypeHereLocal: "Enter here... (e.g., Room, Building, Floor)",
        TypeHereBookcase: "Enter here... (e.g., Green bookcase, Bookcase no. 3)",
        TypeHereShelf: "Enter here... (e.g., Shelf no. 3, Fourth shelf from top to bottom)",
        TypeHerePosition: "Enter here... (e.g., Red box, File on top of the table)",  
        SaveButton: "Save changes",
        CancelButton: "Cancel changes"
      },
      UnableToReturnItemTemporality: {
        message: "Unable to return Item Temporality"
      },
      SelectTemporality: {
        message: "Select Temporality"
      },
      SelectDocumentExpiration: {
        message: "Select document expiration"
      },
      DocumentValidity: {
        message:"Document validity"
      },
      Validity: {
        message:"Validity"
      },
      EmptyTemporalityField:{
        message: 'Empty temporality field'
      },
      EmptyDocumentValidityField:{
        message: 'Empty document validity field'
      },
      RetroactiveDueDate:{
        message: 'Retroactive due date'
      },
      HasExpired:{
        message: 'Has expired'
      },
      Option:{
        message: "Set status to"
      },
      RemoveTemporality:{
        message: "Remove temporality"
      },
      Reload: {
        message: "Reload"
      },
      AssignServiceUser: {
        message: "Assign service user"
      },
      ErrorReceivingAdminUsers: {
        message: "Error receiving admin users"
      },
      Dashboard: {
        storageLimit: "Your storage limit is",
        generalDataUsage: "General data usage",
        storageInUse: "Storage in use",
        freeStorage: "Free storage",
        wsDataUsage: "Data usage in workspaces",
        storageUseWithoutContract: "No max storage set",
        OrganizationDoesntHaveWorkspace: "Organization Doesn't have any workspace."
      },
      Dwg:{
        size:"Size",
        palette:"Palette",
        orientation:"Orientation",
        converterType:"Converter type",
        backgroundColor:"Background color",
        title:"Select the options below to view the file"
      },
      Selecionar:{
        message:"Select"
      },
      YourBrowserDoesNotSupportThePrintingFunction: {
        message: "Your browser does not support the printing function."
      },
      ChooseColumns: {
        message: 'Choose columns',
        description: 'Description',
        validationRule: 'Validation rule',
        term: 'Term',
        size: 'Size',
        createdAt: 'Created at',
      },
      FilterBy: {
        message: "Filter by status",
        toolTipMessage:"The status defines the state, positioning or situation of the document in the process in which it is found.",
        placeholderMessage:"Select..."
      },
      WorkspaceItemFlowListing:{
        responsible: "Responsible",
        date: "Date",
        progress: "In progress",
        waiting: "Waiting...",
        by: "By",
        dynamic: "Dynamic responsable"
      },
      OrganizationMessages: {
        "problemWithResources": "We've some troubles to get back your resources.",
        "resourceAlert": "Contract doesn't have the api call resource."
      },
      ErrorViwer:{
        title:"It was not possible view this file",
        description:"An unexpected error occurred while trying to view. Try viewing the file again."
      },
      ViwerAutomaticDwg:{
        message:"Generate cad visualization data in the background."
      },
      DuplicateFolder: {
        message:"Duplicate folder"
      },
      NameMustNotIncludeEitherSlashes: {
        message: "Name must not include either \ or /"
      },
      MaintainPermissions: {
        message:"Maintain permissions"
      },
      DuplicationOf: {
        message:"Duplication of"
      },
      CopyDocumentLink: {
        message:"Copy document link",
      },
      ItemIdNotFound: {
        message: "Item id not found",
      },
      WithoutPermission: {
        message: "Without permission",
      },
      LoggedHome: {
        QuickAccessContent: "Save your frequently used paths and files and access them quickly from here or wherever you are.",
        QuickAccessTitle: "Quick Access",
        OrganizationsContent: 'The most visited or favorited workspaces are available right below. To view all, click on "Workspace".',
        OrganizationsTitle: "Organizations",
        RecentActivities: "Recent Activities",
        RecentActivitiesContent: 'Access recently performed activities. To view all, click on "Recent Activities".',
        FileName: "File name",
        Action: "Action",
        Responsible: "Responsible",
        LastModification: "Last modification",
        favoriteSuccess: "successfully favorited.",
        favoriteExclude: "Favorite item deleted.",
        favoriteError: "favorite already exists.",
        NoRecentActivities: "No recent activities",
        MovingOrganization: "Start moving your organization through workspaces. This way, you will be able to view activities here"
      },
      Minicard: {
        File: "File",
        Folder: "Folder",
        Desfav: "Unfavorite",
        NothingViewMiniCardTitle: "No saved itens",
        NothingViewMiniCardContent: "No items have been saved yet.",
      },
      LogErrors: {
        browser: "Browser",
        errorFound: "Error Found",
        createdDate: "Created Date",
        server: "Server",
        method: "Method",
        stackTrace: "Stack Trace",
        userMail: "User Email",
        line: "Line",
        search: "Search..."
      },
      FileNotFound: {
        message: "File not found"
      },
      Divider: {
        message: "or"
      },
      Navbar: {
        placeholderImputText: "What are you looking for...",
        labelCheckbox: "Only in this folder"
      }
  }
}
