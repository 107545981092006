import { Grid } from "@mui/material"

interface IOrgInfoActivitiesWrapper {
    children: React.ReactNode;
}

export const OrgInfoActivitiesWrapper: React.FunctionComponent<IOrgInfoActivitiesWrapper> = ({
    children
}) => {
    return (
        <Grid item width={{
            xl: "100%",
            lg: "98%",
          }} border="1px solid #0000001A" borderRadius="10px" padding="20px">
            { children }
        </Grid>
    )
}