import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { OrganizationInfoTrackerColumn } from "../../../../../components/OrganizationInfoTrackerColumn/OrganizationInfoTrackerColumn";
import WorkSpaceItemListFileComponentImage from "../../../../WorkSpaceItem/WorkSpaceItemListFileComponentImage";

interface IOrgInfoTrackerListItem {
    itemExtension: string;
    nameItem: string;
    message: string;
    user: string;
    emailUser: string;
    dateCreateTracker: string;
}

export const OrgInfoTrackerListItem: React.FunctionComponent<IOrgInfoTrackerListItem> = ({
    emailUser,
    itemExtension,
    message,
    nameItem,
    user,
    dateCreateTracker
}) => {
    return (
          <OrganizationInfoTrackerColumn>
            <Grid item xs={3} display="flex" alignItems="center">
              <Box width="28px" height="28px" sx={{
                background: "#E9EFF4 0% 0% no-repeat padding-box",
                borderRadius: "4px",
                opacity: 1,
                mr: "10px"
              }} display="flex" justifyContent="center" alignItems="center">
                <WorkSpaceItemListFileComponentImage 
                  extension={itemExtension}
                  isFolder={false}
                  boxLayout={false}
                />
              </Box>
              <Typography
                sx={{
                  textAlign: "left",
                  font: "normal normal 600 Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  opacity: 1,
                  fontSize: {
                    lg: "12px",
                    xl: "14px"
                  }
                }}
                maxWidth="200px"
                noWrap
                textOverflow="ellipsis"
                overflow="hidden"
              >
                { nameItem }
              </Typography>
            </Grid>
            <Grid item xs={3} display="flex" alignItems="center">
              <Typography
                sx={{
                  textAlign: "left",
                  font: "normal normal 600 Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  opacity: 1,
                  fontSize: {
                    lg: "12px",
                    xl: "14px"
                  }
                }}
              >
                { message }
              </Typography>
            </Grid>
            <Grid item xs={3} display="flex" alignItems="center">
              <Typography
                  sx={{
                    textAlign: "left",
                    font: "normal normal 600 Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    display: "flex",
                    fontSize: {
                      lg: "12px",
                      xl: "14px"
                    }
                  }}
                  maxWidth="360px"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  noWrap
                >
                  { user }
                  <Typography sx={{ textAlign: "left",
                    font: "normal normal 600  Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 0.5,
                    ml: "5px" }}
                    fontSize={{
                      lg: "12px",
                      xl: "14px"
                    }}
                    >
                      ({ emailUser })
                    </Typography>
                </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  textAlign: "end",
                  font: "normal normal 600 Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  opacity: 1,
                  mt: "14px",
                  fontSize: {
                    lg: "12px",
                    xl: "14px"
                  }
                }}
              >
                { moment(dateCreateTracker).format("DD/MM/YYYY") }
              </Typography>
            </Grid>
          </OrganizationInfoTrackerColumn>
    )
}