import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { OrgInfoTrackerListingHeaderItem } from "../../../../components/OrgInfoTrackerListingHeaderItem/OrgInfoTrackerListingHeaderItem";
import { useHeaderActions } from "./hooks/useHeaderActions";

interface IOrgInfoTrackerListingHeader {}

export const OrgInfoTrackerListingHeader: React.FunctionComponent<IOrgInfoTrackerListingHeader> = () => {
    const { headerActions } = useHeaderActions();

    return (
        <Grid container>
            { headerActions != undefined && (
                headerActions.map(ha => (
                    <OrgInfoTrackerListingHeaderItem
                        conditionalToHaveEndAlignment={ha.conditionalToHaveEndAlignment}
                    >
                        { ha.children }
                    </OrgInfoTrackerListingHeaderItem>
                ))
            ) }
        </Grid>
    )
}