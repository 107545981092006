import React, { useState, useMemo, useEffect, useRef, MutableRefObject } from "react";
import styles from "./Navbar.module.css";
import { IIconProps, Icon } from "@fluentui/react/lib/Icon";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider/useAuth";
import  CommandBar from "../CommandBar/CommandBar";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import  Btn, { TypeStyle }  from '../Button/Btn';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react/lib/SearchBox';
import { Organization } from "../../model/OrganizationModel";
import { WorkSpace } from "../../model/WorkSpaceModel";
import { IContextualMenuListProps, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import {GetWorkspaceForNav} from "./GetWorkspaceForNav/GetWorkspaceForNav"
import { useTranslation } from "react-i18next";
import NavbarWorkSpaceOrg from "./NavBarWorkSpaceOrg";
import NavbarValidation from "./NavBarValidation";
import {useSearchParams } from "react-router-dom";
import TextFieldComp from "../TextField/TextFieldComp";
import { ContentbarProps } from "../Contentbar/ContentSide/ContentSideComp";
import ContentbarListHamb from "../Contentbar/ContentHamb/ContentHambList";
import {GetOneItem} from "../../services/Item/ItemServicesGet"
import { RemoveOrganizationLocalStorage, RemoveWorkspaceLocalStorage } from "../../pages/PublicLayout/RedirectManager";
import {GetAllTags} from "../../services/TagService"
import { TagReturn } from "../../model/Tags";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IconButton } from "@fluentui/react";
import { Box } from "@mui/material";
import homeSvg from '../../assets/icons/home.svg'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip 
    {...props} 
    arrow 
    classes={{ popper: className }}
    ref={props.ref}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#818181",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#818181",
    borderRadius: "8px",
    padding: "8px"
  },
}));

const chevronRightMedIcon: IIconProps = {
  iconName: 'ChevronRightMed', 
  style: {
    fontSize: "12px",
    padding: 5, 
    marginTop:"auto", 
    marginBottom:"auto"
  } 
};
interface NavbarProps {
  Organization:Organization|undefined;
  Workspace:WorkSpace|undefined;
  OrgWorkspace:WorkSpace|undefined;
  OrgValidation:WorkSpace|undefined;
  contentbar: ContentbarProps[];
  updateContentbar: () => void;
  resetIdSelected: () => void;
}

const Navbar : React.FC<NavbarProps> = ({Organization, Workspace, OrgWorkspace,OrgValidation, contentbar, updateContentbar, resetIdSelected}) => {
  const MenuHamb = () => <Icon iconName="CollapseMenu" className={isActive ? styles.iconMobileActived : styles.iconMobile}/>;
  const [isActive, setActive] = useState(false);
  const navegate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();
  const { t, i18n } = useTranslation();
  const [itemsOrgWorkspace, setItemsWorkspace] = useState<IContextualMenuItem[]>([]);
  const [searshName, setSearshName] = useState<string>("");
  const [verifyTag, setVerifyTag] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
	const liRef = useRef<HTMLLIElement | null>(null)

  const [items, setItems] = useState<IContextualMenuItem[]>([]);
  const [textSearsh, setTextSearsh] = useState<string>("");
  const [isOrganizationPage, setIsOrganizationPage] = useState<boolean>(false)
  const [tagReturn, setTagReturn] = useState<TagReturn[]>([]);
  const {idWorkspace} = useParams();
  const menuRef = useRef(null);
  useOutsideMenu(menuRef);

  function useOutsideMenu(ref: MutableRefObject<any>) {
    useEffect(() => {
      function handleClickMenu(event: Event) {
        if (ref.current && !ref.current.contains(event.target)) {          
          setActive(isActive);   
        }
      }
      document.addEventListener("mousedown", handleClickMenu);
      return () => {
        document.removeEventListener("mousedown", handleClickMenu);
      };
    }, [ref]);
  }


  useEffect(() => {
    if(idWorkspace != undefined)
    {
      GetAllTags(idWorkspace,0,15,searshName.replace("#","%23"),[])
      .then((tags) =>{
        setTagReturn(tags)
      })
      .catch((erro) =>{
        console.log("eerr",erro)
      })
    }
  },[idWorkspace,searshName])

  useEffect(() => {
      if(window.location.pathname === "/"){
          setIsOrganizationPage(true)
      } else {
          setIsOrganizationPage(false)
      }
  }, [window.location.pathname])
  
  const onAbort = React.useCallback(() => {
    setItems([]);
  }, []);
  const onAbortOrgWorks = React.useCallback(() => {
    setItemsWorkspace([]);
  }, []);
  const onChange = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>|undefined, newValue: string|undefined) => {
    if(newValue!= undefined)
    {
      setTextSearsh(newValue);
    }
    else
    {
      setTextSearsh("");
    }
  }, []);

  useEffect(() => {
    if(Organization!=undefined && Organization.internalName != undefined)
    {
      GetWorkspaceForNav(Organization.internalName, textSearsh.toLowerCase()).then((filteretWOkspace) => {
        setItensWorkspce(filteretWOkspace);
      });
    }
  }, [textSearsh]);


  useEffect(() => {
    if(Organization?.internalName != undefined)
    {
      GetWorkspaceForNav(Organization.internalName, undefined).then((filteretWOkspace) => {
        setItensWorkspce(filteretWOkspace);
      });
    }
  }, [Organization, Organization?.internalName]);

  function setItensWorkspce(filteretWOkspace:WorkSpace[])
  {
    let filteredItems:IContextualMenuItem[] = [];

    if (!filteretWOkspace || !filteretWOkspace.length) {
      filteredItems.push({
        key: 'no_results',
        onRender: (item, dismissMenu) => (
          <div key="no_results" style={filteredItemsStyle}>
            <Icon iconName="SearchIssue" />
            <span>{t("NotFound.message")}</span>
          </div>
        ),
      });
    }
    else
    {
      filteretWOkspace.forEach((workspace) =>{
        if(workspace.id != undefined)
        {
          filteredItems.push({
            key: workspace.id?.toString(),
            text: workspace.name,
            onClick:sendToWorkspaceRoot,
          });
        }
      });
    }

    setItems(filteredItems);
  }

  const sendToWorkspaceRoot = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem)  => {
    if(ev)
      ev.preventDefault();

   navegate("/" + Organization?.internalName + "/wsallowed/" + item?.key);
  };
  
  const renderMenuList:IRenderFunction<IContextualMenuListProps> =
    (
      menuListProps: IContextualMenuListProps|undefined,
      defaultRender: IRenderFunction<IContextualMenuListProps>|undefined) => {
      if(menuListProps!= undefined && defaultRender!= undefined)
      {
        return (
          <div style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderTopRightRadius: '10px', height: '180px' }}>
            <div style={{ padding: '10px' }}>
              <SearchBox
                onAbort={onAbort}
                onChange={onChange}
                styles={searchBoxStyles}
                value={textSearsh}
                placeholder={t("Navbar.placeholderImputText")}
                showIcon
              />
            </div>
            {defaultRender(menuListProps)}
          </div>
        );
      }
      else
      {
        return null;
      }
    };

  const menuProps2 = useMemo<IContextualMenuProps>(
    () => ({
      onRenderMenuList: renderMenuList,
      items,
      styles: {
        root: {
          maxHeight: '180px'
        },
        subComponentStyles: {
          callout: {
            root: {
              boxShadow: 'none'
            },
            calloutMain: {
              borderRadius: '0px 10px 10px 10px',
              boxShadow: 'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px'
            }
          }
        }
      },
    }),
    [items]
  );


  const logout = (event: any) => {
    event.preventDefault();

    auth.logout();
    if (!auth.token) {
      return <Navigate to="/login" />;
    }
  };

  const toggleClass = () => {
    updateContentbar();
    setActive(!isActive);
  };

  function navegateListOrg()
  {
    resetIdSelected();
    RemoveOrganizationLocalStorage();
    RemoveWorkspaceLocalStorage();
    navegate("/");
  }
  function navegateListWorkspace()
  {
    resetIdSelected();
    navegate("/" + Organization?.internalName + "/wsallowed");
    
  }

const iconProps = { iconName: "Search" };

const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
  setVerifyTag(false)
 if (event.currentTarget.value != undefined || event.currentTarget.value != "")
  {
    setSearshName(event.currentTarget.value!);
  }
  else
  {
    setSearshName("");
  }
}

const checkedFolder = (event: React.ChangeEvent<HTMLInputElement>) => {
  setIsChecked(event.target.checked);
}

function onClickListTag(name:string,idTag:string){
  setSearshName(name)
  searchParams.set("tag", idTag);
  setVerifyTag(true)
}

useEffect(() => {
  const timer = setTimeout(() => {

    if (searshName != undefined || searshName != "") {

      let tag = searchParams.get("tag");

      if((tag == "" || tag == undefined) && searshName[0] != "#"){
        searchParams.set("search", searshName);
      }
      
      if(isChecked == true){
        searchParams.set("folder","true");
      }
      else{
        searchParams.delete("folder");
      }

      setSearchParams(searchParams);
    }

    if(searshName == "") {
      searchParams.delete("search");
      searchParams.delete("tag");
      setSearchParams(searchParams);
    }
    
  }, 500)

  return () => clearTimeout(timer)
}, [searshName,isChecked])
  return (
    <nav className={styles.navbar}>
      <div className={`${styles.navbar__container} `}>
      <div className={styles.bread}>
          <div className={styles.showMobile}>
            {isOrganizationPage == false && (
              <button 
                className={isActive?
                  `${styles.navbar__menu_btn} ${styles.navbar__menu_btn_open}`:
                  `${styles.navbar__menu_btn} ${styles.navbar__menu_btn_close}`
                } onClick={toggleClass}>
                <MenuHamb />
              </button>
            )}
            <CommandBar />
          </div>
          
          <div className={styles.BtnBar}>
             
          <CustomTooltip title={"Home"} placement="bottom" disableInteractive>
              <div className={styles.PathListItem}>
                <IconButton
                  onClick={navegateListOrg}
                >
                  <img src={homeSvg} />
                </IconButton>
              </div>
            </CustomTooltip>
            {Organization != undefined && (
              <IconButton
                iconProps={chevronRightMedIcon}
                title="ChevronRightMed"
                ariaLabel="ChevronRightMed"
                disabled={true}
                className={styles.buttonIcon}
              />
            )}
            {Organization != undefined && (
              <CustomTooltip title={Organization?.name} placement="bottom" disableInteractive>
                <div className={styles.PathListItem}>
                  <Btn text={Organization?.name} style={TypeStyle.pathButtonSty} onClick={navegateListWorkspace} />
                </div>
              </CustomTooltip>
            )}
            {Workspace != undefined && (
              <IconButton
                iconProps={chevronRightMedIcon}
                title="ChevronRightMed"
                ariaLabel="ChevronRightMed"
                disabled={true}
                className={styles.buttonIcon}
              />
            )}
            
            {Workspace != undefined && (
              <CustomTooltip title={Workspace.name} placement="bottom" disableInteractive>
                  <Btn text={Workspace.name} style={TypeStyle.lastPathButtonStyles} menuProps={menuProps2} pathButtonWithMenu/>
              </CustomTooltip>
            )}
            {OrgWorkspace != undefined && (
              <IconButton
                iconProps={chevronRightMedIcon}
                title="ChevronRightMed"
                ariaLabel="ChevronRightMed"
                disabled={true}
                className={styles.buttonIcon}
              />
            )}
            <NavbarWorkSpaceOrg OrgWorkspace={OrgWorkspace} Organization={Organization} />

            {OrgValidation != undefined && (
              <IconButton
                iconProps={chevronRightMedIcon}
                title="ChevronRightMed"
                ariaLabel="ChevronRightMed"
                disabled={true}
                className={styles.buttonIcon}
              />
            )}
            <NavbarValidation OrgWorkspace={OrgValidation } Organization={Organization}/> 
          </div>

          {isActive&&(
            <div className={styles.breakpoints} ref={menuRef}>
                <ContentbarListHamb contentList={contentbar}/>
            </div>
          )}
          
        <div className={styles.SearchBoxNavbar}>
            {Workspace != undefined && (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    left: '8px',
                    top: '10px',
                  }}
                >
                  <Icon iconName="Search" styles={{
                    root: {
                      fontWeight: 800,
                      color: '#9c9c9c'
                    }
                  }} />
                </Box>
                <input type="text" className={styles.inputSearch} aria-invalid="false"  onChange={onChangeName}value={searshName} placeholder={t("Navbar.placeholderImputText")}/>  
                <div className={styles.elementSearch}>
                  <input  type="checkbox" checked={isChecked}  onChange={checkedFolder} id="folderOnly" />         
                  <label style={{fontSize: "12px",paddingLeft:" 5px",cursor:"pointer",color: '#9c9c9c'}} htmlFor="folderOnly">{t("Navbar.labelCheckbox")}</label> 
                </div>
              </>    
              )}

            {(searshName[0] == "#" && verifyTag == false)&&(
              <div className={styles.DivTag}>
                {tagReturn.map(tag => {
                  return(
                    <ul className={styles.UlListTag}>
                      <li key={idWorkspace} className={styles.listTag} onClick={() => onClickListTag(tag.name,tag.id)} ref={liRef}>
                          <div>
                              {tag.name}
                          </div>
                        </li>   
                    </ul>
                    )
                })}
              </div>
            )}

          </div> 

          <div className={styles.totalMenu}>
            <CommandBar />
          </div>

          {Workspace != undefined && (
            <div className={styles.SearchBoxNavbarMobile}>
              <input type="text" className={styles.inputSearch} aria-invalid="false" value={searshName} onChange={onChangeName}/>  
                <div className={styles.elementSearch}>
                  <div className={styles.lineVertical}></div>
                  <input  type="checkbox" checked={isChecked}  onChange={checkedFolder}/>         
                  <p style={{fontSize: "12px",paddingLeft:" 5px",cursor:"pointer"}}>{t("Navbar.labelCheckbox")}</p>     
                  <div className={styles.lineVerticalRight}></div>
                  <Icon iconName="Search" />
                </div>    
            </div>
          )} 
        </div>      
      </div>
    </nav>
  );
};

const filteredItemsStyle: React.CSSProperties = {
  width: '100%',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const searchBoxStyles: ISearchBoxStyles = {
  root: {
    width: '240px',
    marginTop: '10px',
    borderRadius: '8px',
    border: '1px solid #989898',
    opacity: 1,
  },
  icon: {
    color: '#989898',
    fontWeight: 600
  }
};

export default Navbar;
