import { Box, Grid, Typography } from "@mui/material"
import noTrackerIcon from '../../../../assets/icons/noTrackersIcon.svg'
import { useTranslation } from "react-i18next"

export const NoTrackerView = () => {

    const { t } = useTranslation();

    return (
        <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            border="1px solid #0000001A"
            borderRadius="4px"
            padding={4}
        >
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box 
                    component="img"
                    src={noTrackerIcon}
                    width="100px"
                    marginBottom="20px"
                />
                <Box>
                    <Box marginBottom="8px">
                        <Typography
                            textAlign="center"
                            fontSize="16px"
                            fontWeight="600"
                            color="#2D2D2D"
                        >
                            {t("LoggedHome.NoRecentActivities")}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            textAlign="center"
                            fontSize="14px"
                            color="#2D2D2D"
                            sx={{
                                opacity: .6
                            }}
                            width="391px"
                        >
                            {t("LoggedHome.MovingOrganization")}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}