import React, { useEffect, useState } from "react";
import { TextField, mergeStyles } from '@fluentui/react';
import styles from '../../../WorkSpaceItemList.module.css';
import CheckIcon from "@mui/icons-material/Check";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from 'react-i18next';
import Loader from "react-ts-loaders";
import { SetDescription } from "../../../../../services/Item/ItemServicesCRUD";
import { description } from "../../../WorkSpaceItemListStyle/ColumnStandard";
import descriptionStyles from './DescriptionFieldStyle.module.css';

interface IDescriptionField {
    indexOfArray:number;
    itemType?: string;
    refRef?: React.MutableRefObject<any>;
    descriptionFile?: string;
    id?: string;
    name?: string;
    isDetailPage?: boolean;
    layoutPendencies?: boolean;
    setPopupError: (set: boolean, message: string) => void;
    setPopupOk: (set: boolean, message: string) => void;
    updateNameDescriptionByIndex:(index:number, name:string, isUpload: boolean, description:string) => void;
    isUpload: boolean;
    boxLayout: boolean;
}


const loaderDesc = mergeStyles({
    justifyContent: "right",
    paddingTop: "10px",
    float: "right",
});

export function DescriptionField({
  indexOfArray,
  itemType,
  refRef,
  descriptionFile,
  id,
  name,
  isDetailPage,
  layoutPendencies,
  setPopupError,
  setPopupOk,
  updateNameDescriptionByIndex,
  isUpload,
  boxLayout
}: IDescriptionField) {

  const [descTimed, setDescTimed] = useState<string|undefined>(descriptionFile);
  const [editDesc, setEditDesc] = useState<boolean>(false);
  const [isShown, setIsShown] = useState(false);
  const [isWaitingDescription, setIsWaitingDescription] = useState<boolean>(false);

    const { t } = useTranslation();

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      console.log(event.key)
      if(event.key ===  'Enter'){
        setDescription();
      }
      else if(event.key ===  'Escape'){
        onClickCanceEditDesc();
      }
    }

    function onClickCanceEditDesc() {
      setEditDesc(false);
      setIsShown(false);
    }

    function setDescription() {
      setEditDesc(false);
      if (id != undefined && name != undefined) {
        setIsWaitingDescription(true);
        SetDescription({
          itemDescription: descTimed != undefined? descTimed : "",
          itemId: id,
        })
          .then(() => {
            setDescTimed(descriptionFile);
            updateNameDescriptionByIndex(indexOfArray, name, isUpload, descTimed != undefined? descTimed : "");
            setPopupOk(true, "Modificado!");
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              typeof error.response.data === "string"
            ) {
              setPopupError(true, error.response.data);
            }
            else {
              setPopupError(true, t("renameItem.error"));
            }
          })
          .finally(() => {
            setIsWaitingDescription(false);
          });
      } else {
        setPopupError(true, "Erro");
      }
    }

    function onClickEditDesc() {
      setDescTimed(descriptionFile == undefined ? "" : descriptionFile);
      setEditDesc(true);
    }

    function returnDescription() {
      return (
        <div
          style={{   
            overflow: "hidden",
            wordWrap: "break-word",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
        >
          {isWaitingDescription ? descriptionFile : descriptionFile}
        </div>
        )
    }

    function returnWait() {
      return(
        isWaitingDescription)&& (
          <Loader size={20} className={loaderDesc} />
      )
    }

    const onChangeDescription = React.useCallback(
      (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
          setDescTimed(newValue);
        }
      },
      []
    );

    return(
      editDesc && !layoutPendencies ? 
      (
        <div 
          style={{
            display: 'flex',
            position: 'relative',
            gridArea: description,
            overflow: "hidden",
            overflowWrap: "break-word",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
          ref={refRef}
        >
          <TextField
            value={descTimed}
            onChange={onChangeDescription}
            onKeyDown={onKeyDown}
            styles={{ fieldGroup: { height: '25px', borderRadius: '8px', whiteSpace: 'nowrap', minHeight: '30px' }, field: {
              whiteSpace: 'nowrap',
              border: '1px solid black',
              borderRadius: '8px'
            }, root: { flexGrow: 1 } }}
            borderless
            autoFocus
          />
          <div style={{
            display: 'flex'
          }}>
            <CheckIcon
              onClick={setDescription}
            />
            <CloseOutlinedIcon
              onClick={onClickCanceEditDesc}
            />
          </div>
        </div>
      ):
      (
        <div
          ref={refRef}
          style={{
            display: 'flex',
            position: 'relative',
            gridArea: description,
            overflow: "hidden",
            overflowWrap: "break-word",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
          onMouseEnter={itemType == "folder" ? undefined : () => setIsShown(true)}
          onMouseLeave={itemType == "folder" ? undefined : () => setIsShown(false)}
        >  
          <Tooltip title={descriptionFile} disableInteractive>
            <div
              style={{position:'relative'}}
            >
              {(descriptionFile == undefined || descriptionFile == "") ? (
                    <span className={`${styles.emptySlot}`}>{t("WithoutRule.withoutDesc")}</span>
                  ) : (
                       isDetailPage ? (
                        <Typography sx={{
                          textAlign: "left",
                          font: "normal normal normal 16px/24px Segoe UI",
                          letterSpacing: "0px",
                          color: "#2D2D2D",
                          opacity: 1,
                        }}>
                            { returnDescription() }
                            {returnWait()}
                        </Typography>
                      ) : (
                        <>
                          { returnDescription() }
                          {returnWait()}
                        </>
                      ) 
                  )}
                  
            </div>
          </Tooltip>
          {isWaitingDescription && (
            <div className={styles.load}>
              <CircularProgress color="inherit" size="20px" style={{ margin: "4px" }} />
            </div>
          )}
          {isShown && !layoutPendencies && (
            <>
              <div className={isDetailPage ? undefined : descriptionStyles.editIconStyle}>
                <EditIcon className={styles.EditiconName} onClick={onClickEditDesc} style={{ color: "rgb(17, 77, 136)" }} />
              </div>
            </>
          )}
          </div>
      )
    )
}
