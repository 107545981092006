import { AlertColor, Box } from "@mui/material";
import { forwardRef } from "react";
import { Favorite, QuickAccessWorkspace } from "../../../model/UserModel";
import { MiniCard } from "./MiniCard";
import { ViewMore } from "./ViewMore";
import { MiniCardNothingToShow } from "./MiniCardNothingToShow";


export interface IMinicardCaroulsel {
    favorites: Favorite[];
    setFavorites: (set: Favorite[]) => void;
    informationCardType: "quickAccess" | "organizations"
    setPopupMessage: (set: string | undefined) => void;
    setSeverity: (set: AlertColor) => void;
    nameOrg: string;
    quickAccessWorkspaces: QuickAccessWorkspace[];
    setQuickAccessWorkspaces: (set: QuickAccessWorkspace[]) => void;
}

export const MiniCardCarousel = forwardRef<HTMLDivElement, IMinicardCaroulsel>((props, ref) => {

    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          overflowX: "auto",
          '&::-webkit-scrollbar': {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
      >
        <>
          { props.informationCardType == "quickAccess" ? (
            <>
              { props.favorites.length <= 0 ? (
                <MiniCardNothingToShow />
              ) : (
                <>
                  {props.favorites.filter(x => x.organizationInternalName == props.nameOrg)?.map((favorite, index, list) => (
                    <Box key={index} sx={{ flex: "0 0 auto", marginRight: "10px" }}>
                      <MiniCard 
                        id={favorite?.id}
                        type={favorite?.type}
                        favoriteName={favorite?.favoriteName}
                        extension={favorite?.extension}
                        isFavorited={favorite?.isFavorited!}
                        favorites={list}
                        setFavorites={props.setFavorites}
                        informationCardType={props.informationCardType}
                        setPopupMessage={props.setPopupMessage}
                        setSeverity={props.setSeverity}
                        nameOrg={props.nameOrg}
                        setQuickAccessWorkspaces={props.setQuickAccessWorkspaces}
                        quickWorkspaces={[]}
                      />
                    </Box>
                  ))}
                </>
              ) }
            </>
          ) : (
            <>
              {props.quickAccessWorkspaces?.map((favorite, index, list) => (
                <Box key={index} sx={{ flex: "0 0 auto", marginRight: "10px" }}>
                  <MiniCard 
                    id={favorite?.id}
                    type={1}
                    favoriteName={favorite?.name}
                    extension={undefined}
                    isFavorited={favorite?.isFavorited!}
                    favorites={props.favorites}
                    setFavorites={props.setFavorites}
                    informationCardType={props.informationCardType}
                    setPopupMessage={props.setPopupMessage}
                    setSeverity={props.setSeverity}
                    nameOrg={props.nameOrg}
                    setQuickAccessWorkspaces={props.setQuickAccessWorkspaces}
                    quickWorkspaces={list}
                  />
                </Box>
              ))}
              { props.quickAccessWorkspaces.length > 1 && (
                <ViewMore 
                  redirectUrl={`/${props.nameOrg}/wsallowed`}
                /> 
              ) }
            </>
          ) }
        </>
      </Box>
    );
  });