import { Box, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import LoaderComp from '../../../../../components/Loader/LoaderComp';
import Btn, { TypeStyle } from '../../../../../components/Button/Btn';
import { BackAndFowardInterface } from '../BackAndFowardComponent';
import { MenuStyles } from '../styles/MenuStyles';

export default function ActionsButton(props: BackAndFowardInterface) {

    const { t } = useTranslation();

    const backOperation = props.backOperation 
    const forwardOperation = props.forwardOperation

    const truncatedBackOperation = backOperation?.trim().length! > 15 ? backOperation?.slice(0, 15) + "..." : backOperation
    const truncatedForwardOperation = forwardOperation?.trim().length! > 15 ? forwardOperation?.slice(0, 15) + "..." : forwardOperation

    return (
        <>
            {props.actionForwadOrBackWait ? (
                <Box><LoaderComp/></Box> 
            ) : (
               
                <Btn
                    text={t("Action.message")}
                    menuProps={{items: [
                        {
                            key: "back",
                            text: truncatedBackOperation,
                            onClick: props.sendToBack,
                            title: backOperation
                        },
                        {
                            key: "foward",
                            text: truncatedForwardOperation,
                            onClick: props.sendToForwardItem,
                            title: forwardOperation
                        },
                    ],
                        styles: MenuStyles
                    }}
                    style={TypeStyle.startButton}
                />
            )}
        </>
    )
}