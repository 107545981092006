import { useEffect, useState } from 'react';
import { tableWidthHandler } from '../../stylehandlers/stylesHandlers';

export const useTableDims = (isSidebarCollapsed: boolean, fullScreenSetted: boolean, isNotebookWidth: boolean) => {
    const [tableDims, setTableDims] = useState<{ width: string, height: string }>({ width: "1000px", height: "50%" });

    useEffect(() => {
        setTableDims(tableWidthHandler(isSidebarCollapsed, fullScreenSetted, isNotebookWidth));
    }, [isSidebarCollapsed, fullScreenSetted])

    return { tableDims }
}