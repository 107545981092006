import { Box, Card, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";

interface IViewMore {
    redirectUrl: string;
}

export function ViewMore({ redirectUrl }: IViewMore) {
    
    const navigate = useNavigate();
    
    return (
        <Box
            onClick={() => {
                navigate(redirectUrl)
            }}
            sx={{
                cursor: "pointer"
            }}
        >
        <Card
          elevation={0}
          sx={{
            width: "242px",
            border: "1px solid #0000001A",
            borderRadius: "3px",
            position: "relative",
            mr: "10px",
            display: "flex",
            alignItems: "center",
            padding: "11px",
            height: "62px",
            justifyContent: "center",
            backgroundColor: "white",
            ':hover': {
                backgroundColor: "#22638D1A",
                opacity: 1
            }
          }}
        >
              <Typography 
                sx={{
                    textAlign: "left",
                    font: "normal normal normal 14px/21px Segoe UI",
                    letterSpacing: "0px",
                    color: "#22638D",
                    opacity: 0.5,
                }}
              >
                Ver lista completa
              </Typography>
        </Card>
      </Box>
    )
}