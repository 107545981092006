import { Grid } from "@mui/material"

interface IOrganizationInfoWrapper {
    children: React.ReactNode;
    userError: boolean;
}

export const OrganizationInfoWrapper: React.FunctionComponent<IOrganizationInfoWrapper> = ({
    children,
    userError
}) => {
    return (
        <Grid container height="100%" width="100%" sx={{ opacity: `${userError ? .3 : 1 }` }}>
            <Grid container height="100%" sx={{
            width: {
                xl: "1320px",
                lg:"90%",
                sm: "80%",
                md: "70%"
            }
            }} margin="0 auto">
                { children }
            </Grid>
        </Grid>
    )
}