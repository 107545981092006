import React from 'react';

interface IStarIcon {
  width: string;
  height: string;
  opacity: number
}

const StarIcon: React.FunctionComponent<IStarIcon> = ({
  height,
  width,
  opacity
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 13.999" opacity={opacity}>
      <path id="XMLID_328_" d="M15.947,7.26a.972.972,0,0,0-.774-.659l-3.654-.547L9.873,2.561a.96.96,0,0,0-1.747,0L6.5,6.04,2.827,6.6a.972.972,0,0,0-.774.659,1,1,0,0,0,.232,1.023l2.66,2.723-.628,3.838a.992.992,0,0,0,.405.979.945.945,0,0,0,1.01.057l3.244-1.812,3.29,1.812a.946.946,0,0,0,1.01-.057.991.991,0,0,0,.405-.979L13.053,11l2.661-2.718A1,1,0,0,0,15.947,7.26Z" transform="translate(-1.999 -2)" fill="#22638d" opacity="0.8"/>
    </svg>
  )
};

export default StarIcon;

interface IStarSlashedIcon {
  width: string;
  height: string;
  opacity: number
}

export const StarSlashedIcon: React.FunctionComponent<IStarSlashedIcon> = ({
  height,
  width,
  opacity
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} opacity={opacity} viewBox="0 0 14 14">
      <g id="_8673902_ic_fluent_star_off_filled_icon" data-name="8673902_ic_fluent_star_off_filled_icon" transform="translate(-2 -2)">
        <path id="Caminho_375" data-name="Caminho 375" d="M2.9,2.154a.525.525,0,0,0-.742.742L5.517,6.259l-2.705.393a.945.945,0,0,0-.524,1.612l2.671,2.6-.63,3.676a.945.945,0,0,0,1.371,1L9,13.8l3.3,1.736a.945.945,0,0,0,1.371-1l-.027-.155L15.1,15.847a.525.525,0,1,0,.742-.742Z" fill="#22638d"/>
        <path id="Caminho_376" data-name="Caminho 376" d="M17.9,8.369l-2.644,2.577L9.3,4.988l1.042-2.112a.945.945,0,0,1,1.695,0l1.651,3.344,3.691.536A.945.945,0,0,1,17.9,8.369Z" transform="translate(-2.19 -0.105)" fill="#22638d"/>
      </g>
    </svg>
  )
};

