import { useUpload } from "../../../context/UploadProvider/useUpload";
import { FolderInformation, WorkSpaceUserName } from "../../../model/ItemModel";
import { WorkItemProps } from "./WorkItemProps/WorkItemProps";
import WorkSpaceItemListFileComponent from "./WorkSpaceItemListFileComponent";
import { useParams } from "react-router-dom";
import WorkSpaceItemListFileShrink from "./WorkSpaceItemListFileShrink";

export interface IWorkSpaceItemListFileUpload {
    newerUploadList: WorkSpaceUserName[];
    returnParamens: (index: number, workSpaceC: WorkSpaceUserName, currentFolderInternal: FolderInformation, isRes: boolean) => WorkItemProps
    currentFolder: FolderInformation;
    boxLayout: boolean;
}


export default function WorkSpaceItemListFileUpload({ newerUploadList, returnParamens, currentFolder, boxLayout }: IWorkSpaceItemListFileUpload) {

    if (newerUploadList.length <= 0)
    {
        return (<></>)
    }

    return (
        <>
            { newerUploadList.map((item, index) => (
                <>
                    { boxLayout ? (
                        <WorkSpaceItemListFileShrink 
                            key={item.id}
                            workItemProps={returnParamens(index, item, currentFolder, false)}
                            isUpload={true}
                        />
                    ) : (
                        <WorkSpaceItemListFileComponent 
                            key={item.id}
                            workItemProps={returnParamens(index, item, currentFolder, false)}
                            isUpload={true}
                        />
                    ) }
                </>
            )) }
        </>
    )
}