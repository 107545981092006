import { Grid } from "@mui/material";

interface IOrganizationInfoTrackerColumn {
    children: React.ReactNode;
}

export const OrganizationInfoTrackerColumn: React.FunctionComponent<IOrganizationInfoTrackerColumn> = ({ children }) => {
    return (
        <Grid container width={{
            lg: "100%",
            xl: "1280px"
          }} height="48px" border="1px solid #0000001A" borderRadius="4px" mt="12px" pl="10px" pr="10px">
            { children }
        </Grid>
    )
}