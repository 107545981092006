import { LogError } from "../model/LogError";
import { Api } from "./api";

export async function GetAllLogErrors(skip: number, limit: number, name: string, begin?: string, end?: string) {
    const baseUrl = "Log/GetAllLogErrors";
    const params: string[] = [];

    if (name !== "") {
        params.push(`name=${name}`);
    }

    if (begin !== undefined) {
        params.push(`begin=${begin}`);
    }
    
    if (end !== undefined) {
        params.push(`end=${end}`);
    }
    
    const queryString = params.length > 0 ? `&${params.join('&')}` : '';
    const fullUrl = `${baseUrl}?skip=${skip}&limit=${limit}${queryString}`;
    
    const request = await Api.get<LogError[]>(fullUrl);
    return request.data;
}