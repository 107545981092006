import { useEffect } from 'react';
import { Grid, TextField, Typography } from "@mui/material";
import DocumentDetailPhButtons from "./components/DocumentDetailPhButtons/DocumentDetailPhButtons";
import { useState } from 'react';
import { GetFisicPosition, UpdateFisicPosition } from "../../../../../../../../services/Item/ItemServicesCRUD";
import { FisicItemInformation } from "../../../../../../../../model/ItemModel";
import { IPersistedPhLocation, IShowInfo } from "../../../../../../DocumentDetail";
import { useTranslation } from 'react-i18next';
import LoaderComp from '../../../../../../../../components/Loader/LoaderComp';

interface IDocumentDetailPhLocation
{
    itemId: string;
    setSnackBarMessage: (set: string) => void;
    setShowViwerInfo: (set: IShowInfo) => void;
    showViwerInfo: IShowInfo;
    isNotebookWidth: boolean;
    isSidebarCollapsed: boolean;
}

export default function DocumentDetailPhLocation({
    itemId,
    setSnackBarMessage,
    setShowViwerInfo,
    showViwerInfo,
    isNotebookWidth,
    isSidebarCollapsed
}: IDocumentDetailPhLocation)
{
    const { t } = useTranslation();
    const [internalLocalPosition, setInternalLocalPosition] = useState<IPersistedPhLocation>({} as IPersistedPhLocation)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingGetFisicInformation, setLoadingGetFisicInformation] = useState<boolean>(false);

    useEffect(() => {
        setShowViwerInfo({ ...showViwerInfo, value: '2' });
        setLoadingGetFisicInformation(true);
        GetFisicPosition(itemId)
        .then(data => {
            setInternalLocalPosition({
                local: data.local!,
                position: data.position!,
                bookshelf: data.bookShelf!,
                shelf: data.shelf!
            })
        })
        .catch(err => {
            console.log(err.response.data)
        })
        .finally(() => {
            setLoadingGetFisicInformation(false)
        })
    }, [])

    function localHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            local: e.currentTarget.value
        });
    }

    function bookShelfHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            bookshelf: e.currentTarget.value
        })
    }

    function shelfHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            shelf: e.currentTarget.value
        })
    }

    function positionBoxHandler(e: React.ChangeEvent<HTMLInputElement>)
    {
        setInternalLocalPosition({
            ...internalLocalPosition,
            position: e.currentTarget.value
        })
    }

    function updatePositionsHandler()
    {
        const newPosition: FisicItemInformation = {
            ItemId: itemId,
            bookShelf: internalLocalPosition.bookshelf,
            local: internalLocalPosition.local,
            position: internalLocalPosition.position,
            shelf: internalLocalPosition.shelf
        }

        setIsLoading(true);
        UpdateFisicPosition(newPosition)
        .then(() => {
            setInternalLocalPosition({
                local: internalLocalPosition.local,
                position: internalLocalPosition.position,
                bookshelf: internalLocalPosition.bookshelf,
                shelf: internalLocalPosition.shelf
            });
        })
        .catch(error => {
            setSnackBarMessage(error.response.data);
        })
        .finally(() => setIsLoading(false))
    }

    function cancelModifications()
    {
        setInternalLocalPosition({} as IPersistedPhLocation)
    }
    
    function widthHandler() {
        if (isNotebookWidth) {
            if (isSidebarCollapsed) {
                return "100%";
            }
            
            return "calc(100% - 100px)";
        }

        return "100%"
    }

    return (
        <>        
            <Grid container padding={2} gap={2}>
                { loadingGetFisicInformation ? (
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <LoaderComp />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} sx={{
                            display: 'flex'
                            }} gap={2}>
                                <Grid item xs={6}>
                                    <Typography sx={{
                                        font: "normal normal 600 16px/24px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "#2D2D2D",
                                        marginBottom: '11px'
                                        }}
                                    >
                                        Local
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        placeholder={t("DetailPhLocation.TypeHereLocal")}
                                        InputProps={{
                                            sx: {
                                                height: '44px',
                                                borderRadius: '8px'
                                            }
                                        }}
                                        value={internalLocalPosition.local}
                                        onChange={localHandler}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{
                                        font: "normal normal 600 16px/24px Segoe UI",
                                        letterSpacing: "0px",
                                        color: "#2D2D2D",
                                        marginBottom: '11px'
                                        }}
                                    >
                                        {t("StroageInformation.bookcase")}
                                    </Typography>
                                    <TextField 
                                        fullWidth
                                        placeholder={t("DetailPhLocation.TypeHereBookcase")}
                                        InputProps={{
                                            sx: {
                                                height: '44px',
                                                borderRadius: '8px'
                                            }
                                        }}
                                        value={internalLocalPosition.shelf}
                                        onChange={shelfHandler}
                                    />
                                </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: 'flex'
                        }} gap={2}>
                            <Grid item xs={6}>
                                <Typography sx={{
                                    font: "normal normal 600 16px/24px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#2D2D2D",
                                    marginBottom: '11px'
                                }}
                                >
                                    {t("StroageInformation.shelf")}
                                </Typography>
                                <TextField 
                                    placeholder={t("DetailPhLocation.TypeHereShelf")}
                                    fullWidth
                                    InputProps={{
                                        sx: {
                                            height: '44px',
                                            borderRadius: '8px'
                                        }
                                    }}
                                    value={internalLocalPosition.bookshelf}
                                    onChange={bookShelfHandler}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{
                                    font: "normal normal 600 16px/24px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#2D2D2D",
                                    marginBottom: '11px'
                                }}
                                >
                                    {t("StroageInformation.position")}
                                </Typography>
                                <TextField
                                    placeholder={t("DetailPhLocation.TypeHerePosition")}
                                    fullWidth
                                    InputProps={{
                                        sx: {
                                            height: '44px',
                                            borderRadius: '8px'
                                        }
                                    }}
                                    value={internalLocalPosition.position}
                                    onChange={positionBoxHandler}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) }
            </Grid>
            <DocumentDetailPhButtons 
                updatePositionsHandler={updatePositionsHandler}
                isLoading={isLoading}
                cancelModifications={cancelModifications}
                widthHandler={widthHandler()}
            />
        </>
    )
}