import { Api, ApiWithoutToken, ApiAuth } from "../services/api";
import { UserCreate, UserGet, UserDelete, UserProfile, UserGroupsVO , UserTwoFaCode, PasswordReset, MfaAuthVO, MicrosoftUserAccess, Favorite, PreferencesLists} from "../model/UserModel";
import {AxiosRequestConfig} from 'axios';

export async function CreateUser(userCreate:UserCreate) {
    const request = await Api.post<any>(
        "user",
        userCreate
    );

    return request.data; 
  }
  
  export async function GetUser(userId:string) {
    const request = await Api.get<UserGet>(
        "user/" + userId,
    );

    return request.data; 
  }

  export async function GetActualUser(token:string) {
    let config: AxiosRequestConfig={};

    if (config.headers === undefined) {
        config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;
    config.headers.scope = 'greendocs';

    const request = await ApiWithoutToken.get<UserProfile>(
        "user",
        config
    );
    return request.data; 
  }

  export async function SetUserTwofaCode(userTwoFaCode:UserTwoFaCode) {

    const request = await ApiWithoutToken.put<any>(
        "twoFa",
        userTwoFaCode
    );
    
    return request.data; 
  }

  export async function EditUser(userCreate:UserCreate) {
    const request = await Api.put<any>(
        "user",
        userCreate
    );

    return request.data; 
  }

  export async function DeleteUser(userDelete:UserDelete) {
    const request = await Api.delete<any>(
        "user",
        {data: userDelete}
    );
  }

  //Groups
  export async function AddUserGroup(userGroup:UserGroupsVO) {
    const request = await Api.put<any>(
        "user/group",
        userGroup
    );
  }

  export async function RemoveUserGroup(userGroup:UserGroupsVO) {
    const request = await Api.delete<any>(
        "user/group",
        {data: userGroup}
    );
  }

  export async function ResetPassword(passwordReset:PasswordReset) {
    const request = await ApiWithoutToken.put<any>(
        "redefinePassword",
        passwordReset
    );

    return request.data; 
  }

  export async function GetMfaInformation(email: string) {
    const request = await ApiWithoutToken.get<MfaAuthVO>(`user/mfa/${email}`);

    return request.data;
  }

  export async function VerifyOtp(otpCode: string, userEmail: string) {

    let verifyObj = {
      otpCode,
      userId: userEmail
    }

    const request = await ApiWithoutToken.post<MfaAuthVO>(`user/verifyOtp`, verifyObj);

    return request.data;
  }

  export async function SetFavorites(favorite: Favorite, internalName: string) {
    const request = await Api.put<PreferencesLists>(`User/favorites/${internalName}`, favorite)
    return request.data;
  }

  export async function DeleteFavorites(favoriteId: string, internalName: string) {
    const request = await Api.delete<PreferencesLists>(`User/favorites/${favoriteId}/${internalName}`);
    return request.data;
  }