import { Box, Card, CardContent, Grid, Typography, IconButton, AlertColor } from "@mui/material";
import React, { useRef } from "react";
import StarIcon from "../../assets/svg/StarIcon";
import OrganizationIcon from "../../assets/svg/OrganizationIcon";
import ArrowIcon from "../../assets/svg/arrow_icon";
import { Favorite, QuickAccessWorkspace } from "../../model/UserModel";
import { MiniCardCarousel } from "./components/MinicardCarrosel";

interface IInformationCard {
  type: "quickAccess" | "organizations";
  title: string;
  content: string;
  favorites?: Favorite[];
  setFavorites: (set: Favorite[]) => void;
  setPopupMessage: (set: string | undefined) => void;
  setSeverity: (set: AlertColor) => void;
  nameOrg: string;
  quickAccessWorkspaces: QuickAccessWorkspace[];
  setQuickAccessWorkspaces: (set: QuickAccessWorkspace[]) => void;
}

export const InformationCard: React.FunctionComponent<IInformationCard> = ({
  type,
  title,
  content,
  favorites,
  setFavorites,
  setPopupMessage,
  setSeverity,
  nameOrg,
  quickAccessWorkspaces,
  setQuickAccessWorkspaces
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: "left" | "right") => {
    if (carouselRef.current) {
      const scrollAmount = direction === "left" ? -242 : 242;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  
  return (
    <Card elevation={0} sx={{ width: {
      lg: "90%",
      xl: "645px"
    }, height: "190px", border: "1px solid #0000001A", borderRadius: "10px" }}>
      <CardContent>
        <Grid container height="20px" width="100%" display="flex" justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Grid item mr="12px" display="flex" alignItems="center">
                {type === "quickAccess" ? (
                  <StarIcon width="20" height="20" opacity={1} />
                ) : (
                  <OrganizationIcon width="20" height="20" color="#22638D" />
                )}
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "normal normal bold 18px/27px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                  }}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={() => scroll("left")}>
              <ArrowIcon rotation={180} color="#22638D" />
            </IconButton>
            <IconButton onClick={() => scroll("right")}>
              <ArrowIcon rotation={0} color="#22638D" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container height="40px" sx={{
          width: {
            xl: "605px",
            lg: "90%"
          }
        }}>
          <Grid item xs={12} mt="8px">
            <Typography
              sx={{
                textAlign: "left",
                font: "normal normal normal Segoe UI",
                letterSpacing: "0px",
                color: "#969696",
                opacity: 1,
                fontSize: {
                  lg: "12px",
                  xl: "14px"
                }
              }}
            >
              {content}
            </Typography>
          </Grid>
        </Grid>
        <Grid container height="60px" width={{
          lg: "95%",
          xl: "645px"
        }} mt="30px" display="flex">
          <MiniCardCarousel 
            ref={carouselRef} 
            favorites={favorites!}  
            quickAccessWorkspaces={quickAccessWorkspaces}
            setFavorites={setFavorites} 
            informationCardType={type}  
            setPopupMessage={setPopupMessage}
            setSeverity={setSeverity}
            nameOrg={nameOrg}
            setQuickAccessWorkspaces={setQuickAccessWorkspaces}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};
