import React from "react";
import styles from '../LogErrorsList.module.css'
import { useTranslation } from "react-i18next";

interface TemporalityListCompProps {
    ref?: React.Ref<HTMLLIElement>
  }

  const GridTitle: React.ForwardRefExoticComponent<TemporalityListCompProps> =
  React.forwardRef(({  }, ref: React.Ref<HTMLLIElement>) => {
   
    const { t, i18n } = useTranslation();

    return (
        <li className={styles.gridTemporalityTitleLi}>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("LogErrors.browser")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
            {t("LogErrors.errorFound")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("LogErrors.createdDate")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("LogErrors.server")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
            {t("LogErrors.method")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("LogErrors.stackTrace")}
            </span>
          </div>
          <div className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("LogErrors.userMail")}
            </span>
          </div>
          <div style={{ borderRight:"0px"}} className={styles.gridTemporalityTitleDiv}>
            <span className={styles.gridTemporalitySpan}>
              {t("LogErrors.line")}
            </span>
          </div>
        </li>
    );
    
  });
  

  export default GridTitle;