import { ContextualMenu, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToFile } from '../../assets/icons/toFile';
import d4signIcon from '../../assets/icons/assinatura-eletronica.svg';
import downloadIcon from '../../assets/icons/download.svg';
import draftIcon from '../../assets/icons/draft.svg';
import unduDraftIcon from '../../assets/icons/unduDraft.svg';
import editIcon from '../../assets/icons/editar.svg';
import copyFolderStructureIcon from '../../assets/icons/copy-folder-structure.svg';
import deleteicon from '../../assets/icons/excluir.svg';
import moveIcon from '../../assets/icons/mover.svg';
import releaseIcon from '../../assets/icons/release.svg';
import revisionIcon from '../../assets/icons/revisoes.svg';
import sharedFileIcon from '../../assets/icons/share-compartilhar.svg';
import { useProfile } from "../../context/ProfileProvider/useProfile";
import stylesList from "../../pages/Style/List.module.css";
import { useLessMargin } from "../../pages/WorkSpaceItem/ResizeStyles/ListModuleStyles/LessMargin.css";
import { MenuConfig } from "./MenuConfig";
import { TemporalityDocument } from "../../assets/icons/temporalityDocument";
import { ReactComponent as ThreeDotsIcon } from '../PDF/PDFViewer/SVG/tres-pontinhos-detalhes.svg';
import MenuStiledRenderProps from "./MenuStiledRender";
import copy from 'copy-to-clipboard';
import biCopy from '../../assets/icons/bi-copy.svg';

const ITEM_HEIGHT = 48;

export interface MenuContProps {
  menuConfig: MenuConfig
}

function MenuCont(props: MenuContProps) {
  const profile = useProfile();
  const { t, i18n } = useTranslation();
  const [menuItems, setMenuItems] = useState<IContextualMenuItem[]>([]);
  const linkRef = React.useRef(null);
  const liRef = useRef<HTMLDivElement>(null)
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    setShowContextualMenu(true);
  }, []);
  const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const documentLinkUrl = window.location.protocol + '//' + window.location.host + '/file/' + props.menuConfig.idItem;
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const Close = () => {
    setAnchorEl(null);
  };

  const copyDocumentLinkUrl = () => {
    copy(documentLinkUrl);
    props.menuConfig.setPopupOkMessage(true, t("Copy.message"))
  }

  useEffect(() => {
  }, [showContextualMenu])

  function isDownloadMenu(extensionInt: undefined|string )
  {
    if(
      props.menuConfig.extension == "pdf" || 
      props.menuConfig.extension == "docx" || 
      props.menuConfig.extension == "doc" || 
      props.menuConfig.extension == "png" ||
      props.menuConfig.extension == "jpg" ||
      props.menuConfig.extension == "gif" ||
      props.menuConfig.extension == "svg" ||
      props.menuConfig.extension == "bmp" ||
      props.menuConfig.extension == "webp" || 
      props.menuConfig.extension == "xls" ||
      props.menuConfig.extension == "xlsx" ||
      props.menuConfig.extension == "ppt" ||
      props.menuConfig.extension == "pptx" ||
      props.menuConfig.extension == "dwg" ||
      props.menuConfig.extension == "mpp"
    )
    {
      return true;
    }

    return false;
  }

  useEffect(() => {
    
    let context:IContextualMenuItem[] = [];
    if(props.menuConfig.archiving == true && 
      (props.menuConfig.isAdmin || 
       props.menuConfig.ownerId == profile.id ||
       props.menuConfig.permissionType == 3 )){
      context.push({
        key: 'Desarquivar',
        text: "Desarquivar",
        onClick: props.menuConfig.setArchiving,
        itemProps: {
          style: {
            borderBottom: '1px solid black'
          }
        }
      })
    }
    else {
      if (props.menuConfig.layoutPendencies == true) {
        if (props.menuConfig.itemType == "file") {
          if (isDownloadMenu(props.menuConfig.extension)) {
            context.push({
              key: 'Download',
              text: "Download",
              onClick: props.menuConfig.downloadFile,
              onRender: (item) =>
                <MenuStiledRenderProps
                  item={item}
                  widthStandar={true}
                  disabled={false}
                  src={downloadIcon}
                  onClick={() => props.menuConfig.downloadFile()}
                />
            })
          }
        }
      }
      else {
        if (props.menuConfig.itemType == "file") {
          if (
            (props.menuConfig.permissionType == 1 || props.menuConfig.permissionType == 3) &&
            (props.menuConfig.validatioRuleConst == undefined || (props.menuConfig.validatioRuleConst != undefined && props.menuConfig.isReleaseConst == true))
          ) {
            if (
              (profile.id == props.menuConfig.currentUserDraft && props.menuConfig.isReleaseConst == false) ||
              (props.menuConfig.isReleaseConst == true)
            ) {
              context.push({
                key: 'Editar',
                text: t("Item.editBtn"),
                onClick: props.menuConfig.rename,
                onRender: (item) =>
                  <MenuStiledRenderProps
                    item={item}
                    widthStandar={true}
                    disabled={false}
                    src={editIcon}
                    onClick={() => props.menuConfig.rename()}
                  />
              })

              if (!props.menuConfig.isReleaseConst && profile.id == props.menuConfig.currentUserDraft) {
              context.push({
                key: 'Deletar Draft',
                text: t("Item.deleteBtn"),
                onClick: props.menuConfig.excluir,
                onRender: (item) =>
                  <MenuStiledRenderProps
                    item={item}
                    widthStandar={false}
                    disabled={false}
                    src={deleteicon}
                    onClick={() => props.menuConfig.excluir()}
                  />
              })
            }
            }

            if (props.menuConfig.isReleaseConst && (props.menuConfig.isAdmin || props.menuConfig.ownerId == profile.id || (props.menuConfig.permissionType = 1 || props.menuConfig.permissionType == 3))) {
              context.push({
                key: 'Deletar Release',
                text: t("Item.deleteBtn"),
                onClick: props.menuConfig.excluir,
                onRender: (item) =>
                  <MenuStiledRenderProps
                    item={item}
                    widthStandar={true}
                    disabled={false}
                    src={deleteicon}
                    onClick={() => props.menuConfig.excluir()}
                  />
              })
            }
            
            if(props.menuConfig.isReleaseConst == true && props.menuConfig.temporalityDocument != undefined){    
              context.push({
                key: 'Temporalidade',
                text: t("Temporality.message"),
                onClick: props.menuConfig.temporalityDocument,
                onRender: (item) =>
                  <MenuStiledRenderProps
                    item={item}
                    widthStandar={true}
                    disabled={false}
                    elementIcon={{
                      icon: TemporalityDocument,
                      color:"rgb(45, 45, 45)"
                    }}
                    onClick={() => props.menuConfig.temporalityDocument != undefined ? props.menuConfig.temporalityDocument() : undefined}
                  />
              })
            }
            
            context.push({
              key: 'Mover',
              text: t("Move.message"),
              onClick: props.menuConfig.moveOpen,
              onRender: (item) =>
                <MenuStiledRenderProps
                  item={item}
                  widthStandar={true}
                  disabled={false}
                  src={moveIcon}
                  onClick={() => props.menuConfig.moveOpen()}
                />
            })
          }

          if (props.menuConfig.currentFolder?.isEditorCannotSeeRevision == false) {
              context.push({
                key: 'Revision',
                text: t("Revisao.term"),
                onClick: props.menuConfig.revision,
                onRender: (item) =>
                  <MenuStiledRenderProps
                    item={item}
                    widthStandar={true}
                    disabled={false}
                    src={revisionIcon}
                    onClick={() => props.menuConfig.revision()}
                  />
              })
            }
  
          if (props.menuConfig.permissionType != 2) {
            if (isDownloadMenu(props.menuConfig.extension)) {
              context.push({
                key: 'Download',
                text: "Download",
                onClick: props.menuConfig.downloadFile,
                onRender: (item) =>
                  <MenuStiledRenderProps
                    item={item}
                    widthStandar={true}
                    disabled={false}
                    src={downloadIcon}
                    onClick={() => props.menuConfig.downloadFile()}
                  />
              })
            }

            if (
              (props.menuConfig.permissionType == 1 || props.menuConfig.permissionType == 3) &&
              (props.menuConfig.validatioRuleConst == undefined || (props.menuConfig.validatioRuleConst != undefined && props.menuConfig.isReleaseConst == true))
            ) {
              if (props.menuConfig.isReleaseConst == true) {
                context.push({
                  key: 'set',
                  text: t("Item.setDraft"),
                  onClick: props.menuConfig.setDraft,
                  onRender: (item) =>
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={draftIcon}
                      onClick={() => props.menuConfig.setDraft()}
                    />
                })
              }
              else if (props.menuConfig.isReleaseConst == false) {
                context.push({
                  key: 'set',
                  text: t("Item.setRelease"),
                  onClick: props.menuConfig.serRelease,
                  onRender: (item) =>
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={releaseIcon}
                      onClick={() => props.menuConfig.serRelease()}
                    />
                });
              }
              if(props.menuConfig.undoDraftIsOpen && !props.menuConfig.isReleaseConst){
                context.push({
                  key: 'UndoDraft',
                  text: t("Item.undoDraft"),
                  onClick: props.menuConfig.undoDraft,
                  onRender: (item) =>
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={false}
                      src={unduDraftIcon}
                      onClick={() => props.menuConfig.undoDraft()}
                    />
                })
              }
            }

            if (props.menuConfig.isD4SignSignedConst == true) {
              context.push({
                key: 'set',
                text: t("Item.d4SignVersion"),
                onClick: props.menuConfig.ExibirItemD4SignTrue,
                onRender: (item) =>
                  <MenuStiledRenderProps
                    item={item}
                    widthStandar={true}
                    disabled={false}
                    src={d4signIcon}
                    onClick={() => props.menuConfig.ExibirItemD4SignTrue()}
                  />
              })
            }

            if(props.menuConfig.isReleaseConst == true && (props.menuConfig.isAdmin || props.menuConfig.ownerId == profile.id))
            {        
                context.push({
                  key: 'Arquivar',
                  text: t("Arquivar.message"),
                  onClick: props.menuConfig.setArchiving,
                  onRender: (item) =>           
                    <MenuStiledRenderProps
                      item={item}
                      widthStandar={true}
                      disabled={props.menuConfig.existStorageArchive == undefined || props.menuConfig.existStorageArchive != true}
                      elementIcon={{
                        icon: ToFile,
                        color:"rgb(45, 45, 45)"
                      }}
                      onClick={() => props.menuConfig.setArchiving()}
                    />
                })                   
            }
          }
            
          if(
            (props.menuConfig.isAdmin || props.menuConfig.ownerId == profile.id))
          {
            context.push({
              key: 'Shared',
              text: t("SharedFile.Menu"),
              onClick: props.menuConfig.setShared,
              onRender: (item) =>           
                <MenuStiledRenderProps
                  item={item}
                  widthStandar={true}
                  disabled={false}
                  src={sharedFileIcon}
                  onClick={() => props.menuConfig.setShared()}
                />
            })
          }
        }
        else if (props.menuConfig.itemType == "folder" && (props.menuConfig.permissionType == 3 || (props.menuConfig.isAdmin || props.menuConfig.ownerId == profile.id))) {
          if (props.menuConfig.isAdmin || props.menuConfig.ownerId == profile.id) {
            context.push({
              key: 'Editar',
              text: t("editItem.message"),
              onClick: props.menuConfig.setEditFolderOpen,
              onRender: (item) =>
                <MenuStiledRenderProps
                  item={item}
                  widthStandar={true}
                  disabled={false}
                  src={editIcon}
                  onClick={() => props.menuConfig.setEditFolderOpen()}
                />
            })

            context.push({
              key: 'CopyFolderStructure',
              text: t("DuplicateFolder.message"),
              onClick: props.menuConfig.openCopyFolderStructureDrawer,
              onRender: (item) =>
                <MenuStiledRenderProps
                  item={item}
                  widthStandar={true}
                  disabled={false}
                  src={copyFolderStructureIcon}
                  onClick={() => props.menuConfig.openCopyFolderStructureDrawer()}
                />
            })
          }
          else {
            context.push({
              key: 'Renomear',
              text: t("renameItem.message"),
              onClick: props.menuConfig.rename,
              onRender: (item) =>
                <MenuStiledRenderProps
                  item={item}
                  widthStandar={true}
                  disabled={false}
                  src={editIcon}
                  onClick={() => props.menuConfig.rename()}
                />
            })
          }

          if ((props.menuConfig.isAdmin || props.menuConfig.ownerId == profile.id)) {
          context.push({
            key: 'Deletar Folder',
            text: t("Item.deleteBtn"),
            onClick: props.menuConfig.excluir,
            onRender: (item) =>
              <MenuStiledRenderProps
                item={item}
                widthStandar={true}
                disabled={false}
                src={deleteicon}
                onClick={() => props.menuConfig.excluir()}
              />
          })
        }

          context.push({
            key: 'Mover',
            text: t("Move.message"),
            onClick: props.menuConfig.moveOpen,
            onRender: (item) =>
              <MenuStiledRenderProps
                item={item}
                widthStandar={true}
                disabled={false}
                src={moveIcon}
                onClick={() => props.menuConfig.moveOpen()}
              />
          })
        }
      }
    }
    if (props.menuConfig.itemType == "file") {
      context.push({
        key: 'CopyDocumentLink',
        text: t("CopyDocumentLink.message"),
        onClick: copyDocumentLinkUrl,
        onRender: (item) =>
          <MenuStiledRenderProps
            item={item}
            widthStandar={true}
            disabled={false}
            src={biCopy}
            onClick={copyDocumentLinkUrl}
            tooltipText={documentLinkUrl}
          />
      })
    }
 
    setMenuItems(context);
  }, [
    props.menuConfig.isD4SignSignedConst,
    props.menuConfig.validatioRuleConst,
    props.menuConfig.isReleaseConst,
    profile,
    props.menuConfig.permissionType,
    props.menuConfig.isAdmin,
    props.menuConfig.setArchiving,
    props.menuConfig.existStorageArchive,
    props.menuConfig.temporalityDocument
  ])

  const lessMargin = useLessMargin()



  return (
    <div className={props.menuConfig.openViwer ? stylesList.marginMenu : undefined} ref={liRef}>
      {props.menuConfig.layoutPendencies ? (
        <div className={props.menuConfig.boxLayout ? lessMargin.myDiv : lessMargin.default}>
          {props.menuConfig.extension == "pdf" ? (
            <>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={onShowContextualMenu}
                ref={linkRef}
                sx={{
                  padding: props.menuConfig.boxLayout ? '0px' : '8px'
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <ContextualMenu
                items={menuItems}
                hidden={!showContextualMenu}
                target={linkRef}
                styles={{
                  root: {
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
                  }
                }}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
              />
            </>
          ) : (
            <div style={{ width: "40px" }}>

            </div>
          )}
        </div>
      ) : (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={onShowContextualMenu}
            ref={linkRef}
            sx={{
              padding: props.menuConfig.boxLayout ? '0px' : '8px'
            }}
          >
            <ThreeDotsIcon style={{
              height: "16px",
              width: "16px",
            }} />
          </IconButton>
          <ContextualMenu
            items={menuItems}
            hidden={!showContextualMenu}
            target={linkRef}
            styles={{
              subComponentStyles: {
                callout: {
                  root: {
                    boxShadow: 'none'
                  },
                  css: {
                    boxShadow: 'none'
                  }
                }
              }
            }}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
            id="context_menu"
            calloutProps={{
              style: {
                right: '24px',
                boxShadow: "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",

              }
            }}
          />
        </div>
      )}
    </div>
  );
}

export default MenuCont;