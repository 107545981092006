import React, { useState, useEffect, useCallback, useRef } from "react";
import Btn from "../../../components/Button/Btn";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import styles from "../../Style/List.module.css";
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import { useNavigate, useParams } from "react-router-dom";
import { IComboBox, IComboBoxOption } from '@fluentui/react';
import ComboBoxComp from "../../../components/ComboBox/ComboBoxComp";
import { 
    ValidationRuleVOActivity,
    ValidationRuleVOCreateUpdate,
    ValidationTypes
} from "../../../model/ValidationRuleModel";
import {GetOptions} from "../ResponsabilityReturn/ReturnCombo"
import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import ValidationRuleActivity from './ValidationRuleActivity'
import stylesList from "./ValidationRuleCreateEdit.module.css";
import ValidationRuleCreateEditItem from './ValidationRuleCreateEditItem';
import { GetOneVr, CreateVr, UpdateVr ,GetValidationTypes} from '../../../services/ValidationRuleService'
import MessageComp from "../../../components/Dialog/Message";
import { Label } from '@fluentui/react/lib/Label';
import LoaderComp from '../../../components/Loader/LoaderComp';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import {  IContextualMenuItem,ContextualMenu } from '@fluentui/react/lib/ContextualMenu';

const ValidationRuleCreateEdit  = (props: any) => {
    const { nameOrg} = useParams();
    const { idWorkspace} = useParams();
    const { idValidationRule } = useParams();
    const profile = useProfile();
    const { t, i18n } = useTranslation();
    const [idVr, setIdVr] = useState<string|undefined>(undefined);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [popupOk, setPopupOk] = useState<boolean>(false);
    const [popupError, setPopupError] = useState<boolean>(true);
    const [descriptionNewActivity, setDescriptionNewActivity] = useState<string>("");
    const [lastActivityId, setLastActivityId] = useState<number>(-1);
    const navegate = useNavigate();
    const [activity , setActiviy] = useState<ValidationRuleVOActivity[]>([]);
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
        useBoolean(false);
    const [validateNewEdit, setValidateNewEdit] = useState<ValidationRuleVOActivity | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string>(t("ErroAoSalvar.message"));
    const [alertColor, setAlertColor] = useState<AlertColor>("success");
    const [options, setOptions] = useState<IComboBoxOption[] | undefined>(undefined);
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[]>([]);
    const [validationTypes, SetValidationTypes] = useState<ValidationTypes[]>([]);
    const linkRef = React.useRef(null);

    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      setShowContextualMenu(true);
    }, []);

    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    

    const handleClose = (event:any, reason:any) => {
        setPopupOk(false);
    }

    const handleClosePO = (event:any) => {
        setPopupOk(false);
    }

    const updatePanel = () => {
        setValidateNewEdit(undefined);
        dismissPanel();
      };
    

    const onChangeSetName = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
            setName(newValue);
        }
        else {
            setName("");
        }
      };

      const onChangeSetDescription = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue != undefined) {
            setDescription(newValue);
        }
        else {
            setDescription("");
        }
      };

      const onClickEdit = (validationRuleVOActivity:ValidationRuleVOActivity) => {
        setValidateNewEdit(validationRuleVOActivity);
      };
    
      const onClickDelete = (idParamter:String) => {
        const indexList = activity.findIndex(x => x.id?.toString() == idParamter)
        if(indexList != undefined)
        {
            if (indexList > -1) {
                activity.splice(indexList, 1);
                const newListVR = [...activity];
                setActiviy(newListVR);
            }
        }
      };

      useEffect(() => {
        if(validateNewEdit != undefined)
        {
            openPanel();
        }
      }, [validateNewEdit]);

      useEffect(() => {
        let context: IContextualMenuItem[] = [];

        validationTypes.forEach(validationType => {
            context.push( { key: validationType.index, 
                text: validationType.name,
                onClick: addNew});
        });

        setMenuItems(context);
      }, [lastActivityId, validationTypes]);

      

      function addNew(ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem)
       {
          ev && ev.preventDefault();
    
            if(item?.text == undefined)
            {
                setDescriptionNewActivity(t("ValidationRules.NewActivity"));
            }
            else
            {
                setDescriptionNewActivity(t("New.female") + " " + item?.text);
            }

            let newValidationRuleVOActivity:ValidationRuleVOActivity = {
                id : lastActivityId + 1,
                order: lastActivityId + 1,
                name : "",
                type :item?.key.toString(),
                responsability:[],
                deleted:false
            }

            setValidateNewEdit(newValidationRuleVOActivity);
        }

      useEffect(() => {

        if(nameOrg != undefined)
        {
            GetOptions(t, nameOrg)
            .then((response) =>
            {
                setOptions(response);
            })
            .catch((error) =>{ 
                console.log(error);
            });

            GetValidationTypes(nameOrg)
            .then((result) =>{
                SetValidationTypes(result);
            })
            .catch((error) =>{
            });

            if(idValidationRule != undefined && idValidationRule != "new")
            {
                GetOneVr(idValidationRule)
                .then((result) =>
                {
                    setIdVr(idValidationRule);

                    if(result.name)
                    {
                        setName(result.name);
                    }

                    if(result.description)
                    {
                        setDescription(result.description);
                    }

                    if(result.lastActivityId != undefined)
                    {
                        setLastActivityId(result.lastActivityId);
                    }

                    if(result.activity)
                    {
                        setActiviy(result.activity);
                    }
                })
                .catch((error) => {
                    setPopupError(false);
                });
            }
            else
            {
                setIdVr(undefined);
            }
        }
      }, [idValidationRule, nameOrg]);


    function crudValidationRulle(returnValidation:ValidationRuleVOActivity )
    {
        if(returnValidation.id != undefined)
        {
            //get index by id
            var found = activity.find(x => x.id == returnValidation.id);
            if(found != undefined)
            {
                let index = activity.indexOf(found);
                if(index == -1)
                {
                    setLastActivityId(returnValidation.id)
                    setActiviy([...activity, returnValidation]);    
                }
                else
                {
                    let act:ValidationRuleVOActivity[] = activity;
                    act[index] = returnValidation;
                    setActiviy(act);
                }
            }
            else
            {
                setLastActivityId(returnValidation.id)
                setActiviy([...activity, returnValidation]);
            }
        }
        
        setValidateNewEdit(undefined);
        dismissPanel();
    }

    function closePopUpOk() {
        setPopupError(true);
        navegate("/");
    }

    function saveVr() {
        let vr:ValidationRuleVOCreateUpdate = {
            name: name, 
            description: description,
            activity:activity,
            workSpaceId:idWorkspace
        }

        setErrorMessage(t("ValidationRule.saveOk"));
        setAlertColor("success");

        if(idVr != undefined)
        {
            vr.id = idVr;
            UpdateVr(vr)
            .then((response)=>{
                setPopupOk(true);
            })
            .catch((error)=>{
                if( error.response && error.response.data && typeof error.response.data  === 'string')
                {
                    setErrorMessage(error.response.data);
                }
                else
                {
                    setErrorMessage(t("ErroAoSalvar.message"));
                }

                setAlertColor("error");
                setPopupOk(true);
            });
        }
        else
        {
            CreateVr(vr)
            .then((response)=>{
                setIdVr(response);
                setPopupOk(true);
            })
            .catch((error)=>{
                if( error.response && error.response.data && typeof error.response.data  === 'string')
                {
                    setErrorMessage(error.response.data.toString());
                }
                else
                {
                    setErrorMessage(t("ErroAoSalvar.message"));
                }
                setAlertColor("error");
                setPopupOk(true);
            });
        }
    }

    return (
        <div>
            <h3 className={styles.hList}>{t("ValidationRule.menu")}</h3>
            <TextFieldComp 
                value={name}
                onChange={onChangeSetName}
                label={t("Nome.validation")}
            />
            <TextFieldComp 
                value={description}
                onChange={onChangeSetDescription}
                label={t("Description.message")}
            />

            <div className={stylesList.content}>
                <ul>
                    <div>
                        <li className={` ${stylesList.itemContentDocument} ${stylesList.title} `}>
                            <div className={stylesList.firstDiv}>
                                {t("Type.message")}{" "}
                            </div>
                            <div className={stylesList.secondDiv}>
                                {t("Nome.validation")}{" "}
                            </div>
                            <div className={stylesList.thirdDiv}>
                                {t("Responsible.message")}{" "}
                            </div>
                            <div>
                            <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={onShowContextualMenu}
                            ref={linkRef}
                            >
                            <AddIcon/>
                            </IconButton>
                            <ContextualMenu
                                items={menuItems}
                                hidden={!showContextualMenu}
                                target={linkRef}
                                styles={{
                                root:{
                                    paddingTop:"8px",
                                    paddingBottom:"8px",
                                    boxShadow:"0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",

                                },
                                
                                }}
                                onItemClick={onHideContextualMenu}
                                onDismiss={onHideContextualMenu}
                            />
                        </div>
                        </li>
                    </div>
                    {activity.map((vr) => (
                        <ValidationRuleCreateEditItem
                            key={vr.id}
                            id={vr.id}
                            name={vr.name}
                            responsability={vr.responsability}
                            type={vr.type}
                            deleted={vr.deleted}
                            order={vr.order}
                            onDelete={onClickDelete}
                            onEdit={onClickEdit}
                            backOperationLabel={vr.backOperationLabel}
                            forwardOperationLabel={vr.forwardOperationLabel}
                            options={options}
                            dinamicUserChoice={vr.dinamicUserChoice}
                            term={vr.term}
                            enumTermDescription={vr.enumTermDescription}
                        />
                    ))}
                </ul>
            </div>

            <Btn text={t("Salvar.message")} onClick={saveVr}/>

            <Panel
                isOpen={isOpen}
                closeButtonAriaLabel={t("Fechar.message")}
                onDismiss={updatePanel}
                headerText={descriptionNewActivity}
                isFooterAtBottom={true}
            >              
                {nameOrg != undefined && validateNewEdit != undefined && (
                    <ValidationRuleActivity
                        nameOrg={nameOrg}
                        validateNewEdit={validateNewEdit}
                        returnValidation={crudValidationRulle}
                    />
                )}
            </Panel>

            <MessageComp
                hidden={popupError}
                title={t("ValidationRule.errorFind")}
                closeButtonAriaLabel={t("Fechar.message")}
                buttonMethod={closePopUpOk}
                text={t("Continuar.message")}
            />

            <Snackbar
                open={popupOk}
                autoHideDuration={6000}
                message="Archived"
                onClose={handleClose}
                anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                sx={{ bottom: { xs: 50, sm: 20 } }}
                >
                <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                    {errorMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default ValidationRuleCreateEdit;