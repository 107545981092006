import { AlertColor, Grid } from "@mui/material";
import { InformationCard } from "../../../../components/InformationCard/InformationCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Favorite, QuickAccessWorkspace } from "../../../../model/UserModel";
import { useProfile } from "../../../../context/ProfileProvider/useProfile";
import { WorkSpace } from "../../../../model/WorkSpaceModel";
import { useParams } from "react-router-dom";
import { GetOrgWorkSpaceAdmTrim, GetOrgWorkSpaceTrim } from "../../../../services/WorkSpaceServices";

interface IInformationCardComponent {
    setSeverity: (set: AlertColor) => void;
    setPopupMessage: (set: string | undefined) => void;
    setUserError: (set: boolean) => void;
}

export function InformationCardComponent({
    setSeverity,
    setPopupMessage,
    setUserError
}: IInformationCardComponent) {
    const { preferences } = useProfile();
    const { t } = useTranslation();
    const [favorites, setFavorites] = useState<Favorite[]>(preferences?.favorites != undefined ? preferences?.favorites : []);
    const { nameOrg } = useParams();
    const [workspacesQuickAccessConst, setWorkspacesQuickAccessConst] = useState<QuickAccessWorkspace[]>([]);
    const [workspaces, setWorkspaces] = useState<WorkSpace[]>([]);
    const { adminSystem } = useProfile();

    useEffect(() => {
        if (adminSystem)
        {
            GetOrgWorkSpaceAdmTrim(nameOrg!, 0, 11, false)
            .then(data => {
                setWorkspaces(data);
              })
              .catch(err => {
                setPopupMessage(err.response.data);
                setSeverity("error");
                setUserError(true)
              });
        } else
        {
            GetOrgWorkSpaceTrim(nameOrg!, 0, 11, false)
            .then(data => {
              setWorkspaces(data);
            })
            .catch(err => {
              setPopupMessage(err.response.data);
              setSeverity("error");
              setUserError(true)
            });
        }
    }, [nameOrg])

    function workspaceToQuickAccess(): QuickAccessWorkspace[] {
        if (workspaces != null && workspaces?.length > 0) {
            return workspaces.map(x => {
                let quickAccessItem: QuickAccessWorkspace = {
                    id: x.id!,
                    isFavorited: favorites.map(x => x.id!).includes(x.id!),
                    name: x.name!
                }

                return quickAccessItem
            })
        }

        return [];
    }

    useEffect(() => {
        setWorkspacesQuickAccessConst(workspaceToQuickAccess());
    }, [workspaces, favorites]);

    return (
        <Grid item xs={12} height="190px" display="flex" width="100%" justifyContent="space-between" mb="30px">
            <Grid item xs={6} mr="30px">
                <InformationCard 
                    content={t("LoggedHome.QuickAccessContent")}
                    title={t("LoggedHome.QuickAccessTitle")}
                    type="quickAccess"
                    favorites={favorites}
                    setFavorites={setFavorites}
                    setPopupMessage={setPopupMessage}
                    setSeverity={setSeverity}
                    nameOrg={nameOrg!}
                    quickAccessWorkspaces={workspacesQuickAccessConst}
                    setQuickAccessWorkspaces={setWorkspacesQuickAccessConst}
                />
            </Grid>
            <Grid item xs={6}>
                <InformationCard
                    content={t("LoggedHome.OrganizationsContent")}
                    title="Workspace"
                    type="organizations"
                    setFavorites={setFavorites}
                    quickAccessWorkspaces={workspacesQuickAccessConst}
                    favorites={favorites}
                    setPopupMessage={setPopupMessage}
                    setSeverity={setSeverity}
                    nameOrg={nameOrg!}
                    setQuickAccessWorkspaces={setWorkspacesQuickAccessConst}
                />
            </Grid>
        </Grid>
    )
}