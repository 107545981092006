import React from 'react';

interface IOrganizationIcon {
  width: string;
  height: string;
  color: string;
}

const OrganizationIcon: React.FunctionComponent<IOrganizationIcon> = ({
  height,
  width,
  color
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <path id="apartment_FILL1_wght400_GRAD0_opsz24" d="M120-820v-15.556h4.444V-840h11.111v8.889H140V-820h-8.889v-4.444h-2.222V-820Zm2.222-2.222h2.222v-2.222h-2.222Zm0-4.445h2.222v-2.222h-2.222Zm0-4.444h2.222v-2.222h-2.222Zm4.444,4.444h2.222v-2.222h-2.222Zm0-4.444h2.222v-2.222h-2.222Zm0-4.445h2.222v-2.222h-2.222Zm4.444,8.889h2.222v-2.222h-2.222Zm0-4.444h2.222v-2.222h-2.222Zm0-4.445h2.222v-2.222h-2.222Zm4.444,13.333h2.222v-2.222h-2.222Zm0-4.445h2.222v-2.222h-2.222Z" transform="translate(-120 840)" fill={color}/>
  </svg>
);

export default OrganizationIcon;
