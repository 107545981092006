import { useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import LoaderComp from '../../../../../../../../components/Loader/LoaderComp';
import moment from 'moment';
import { Box } from '@mui/material';
import DocumentDetailTabTitle from '../DocumentDetailTabTitle/DocumentDetailTabTitle';
import DocumentDetailTableWrapper from '../DocumentDetailTable/DocumentDetailTableWrapper/DocumentDetailTableWrapper';
import DocumentDetailTableHeader from '../DocumentDetailTable/DocumentDetailTableHeader/DocumentDetailTableHeader';
import DocumentDetailTableBody from '../DocumentDetailTable/DocumentDetailTableBody/DocumentDetailTableBody';
import { IShowInfo } from '../../../../../../DocumentDetail';
import { useTranslation } from 'react-i18next';

interface IDocumentDetailActivityTable {
    itemTracker: ITrackerItems[] | undefined
    revisionTrackerLoading?: boolean;
    setShowViwerInfo: (set: IShowInfo) => void;
    showViwerInfo: IShowInfo;
    isSidebarCollapsed: boolean;
    fullScreenSetted: boolean;
    isNotebookWidth: boolean
}

export interface ITrackerItems 
{
    nameUser: string;
    ip: string;
    message: string;
    trackerDateTime: string;
    revision: number;
}

export default function DocumentDetailActivityTable({ itemTracker, revisionTrackerLoading, setShowViwerInfo, showViwerInfo, fullScreenSetted, isSidebarCollapsed, isNotebookWidth}: IDocumentDetailActivityTable)  {

    const { t } = useTranslation(); 

    useEffect(() => { 
      setShowViwerInfo({ ...showViwerInfo, value: '2' });
    }, [])

    if (revisionTrackerLoading) 
    {
        return (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <LoaderComp />
            </Box>
        )
    }

    const dataTitle = [
      t("DataTableHeaders.Reviews"), 
      t("DataTableHeaders.Actions"), 
      t("DataTableHeaders.Users"), 
      'IP', 
      t("DataTableHeaders.Date")]

    function renderTitleCells(item: string, index: number)
    {
      return (
        <TableCell>
          <DocumentDetailTabTitle 
            textToShowInButton={item}
          />
        </TableCell>
      )
    }

    function renderTrackerCells(item: ITrackerItems, index: number)
    {
      return (
        <>
          <TableCell component="th" scope="row" sx={{
            letterSpacing: "0px",
            color: "#2D2D2D",
            fontWeight: 'bold'
          }}>
            {item.revision}
          </TableCell>
          <TableCell align="left" sx={{
            letterSpacing: "0px",
            color: "#2D2D2D",
            fontWeight: 'bold'
          }}>
            {item.message}
          </TableCell>
          <TableCell align="left" sx={{
            letterSpacing: "0px",
            color: "#2D2D2D",
            fontWeight: 'bold'
          }}>
            {item.nameUser}
          </TableCell>
          <TableCell align="left" sx={{
            letterSpacing: "0px",
            color: "#2D2D2D",
            fontWeight: 'bold'
          }}>
            {item.ip}
          </TableCell>
          <TableCell align="left" width={150} sx={{
            letterSpacing: "0px",
            color: "#2D2D2D",
            fontWeight: 'bold'
          }}>
            {moment(item.trackerDateTime).format("DD/MM/YYYY hh:mm")}
          </TableCell>
        </>
      )
    }

  return (
    <DocumentDetailTableWrapper
      fullScreenSetted={fullScreenSetted}
      isSidebarCollapsed={isSidebarCollapsed}
      isNotebookWidth={isNotebookWidth}
    >
      <DocumentDetailTableHeader<string>
        data={dataTitle}
        renderCells={renderTitleCells}
      />
      <DocumentDetailTableBody<ITrackerItems> 
        data={itemTracker!}
        renderCells={renderTrackerCells}
      />
    </DocumentDetailTableWrapper>
  );
}
